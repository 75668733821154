<!-- Body -->
<div class="container-fluid page-body-wrapper">

  <!-- Sidebar -->
  <app-sidebar></app-sidebar>

  <!-- Main Wrapper -->
  <div class="main-panel">
    <div class="content-wrapper pt-3">  <!--  style="background-image: url('../../../assets/img/employee/medical-banner-with-doctor-patient\ 1.png'); background-repeat: no-repeat; background-size: cover;" -->

      <h3 class="bs-title" style="position: absolute; margin-top: 15px;">Dashboard</h3>
      <!-- <hr> -->

      <div class="row mt-3">

        <!-- Left Section -->
        <div class="col-sm-8">

          <!-- Text -->
          <div class="row my-5">
            <div class="col-sm-12" style="display: flex;">
              <div title="{{clientname}}"
                style="width: 30%;height: 40px; background-image: linear-gradient(to right,#737373, #E9E9E9); text-transform: uppercase; padding: 10px; color: #fff;  white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
               {{ clientname }}
              </div>
    
              <div
                style="width: 70%; height: 40px;background-color: #F3F3F3; padding: 10px; border: solid 1p #C9C9C9; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                <marquee>{{ employerWelcomeMsg }} </marquee>
              </div>
            </div>                                                                      
          </div>

          <!-- Cards -->
          <div class="row mt-3">
                        
            <!-- Demography Summary -->
            <div class="col-md-6 col-lg-4 col-custom" style="margin-top: 0px;">
              <div class="card graphdiv">
                <img src="../../../assets/img/employee/filter_emp.png" class="filter_emp" alt="img" />
                <h5>Demography Summary</h5>
                <!-- <div [chart]="summarychart"></div> -->
                <img src="../../../assets/img/employee/demosummary.png" alt="demosummary" style="width: 100%; height: 140px;" />
                <div style="width: 100%; display: flex; margin-top: 30px;">
                  <div style="width: 50%; display: flex; margin-right: 20px;"><div style="width: 12px; margin-right: 4px; height: 12px; background: #FFBF3F; position: relative; top: 2px;"></div><b>At inception</b></div>
                  <div style="width: 50%; display: flex;"><div style="width: 12px; margin-right: 4px; height: 12px; background: #F9423A; position: relative; top: 2px;"></div><b>Net changes</b></div>                  
                </div>
                <div style="display: flex;"><div style="width: 12px; height: 12px; margin-right: 4px; background: #173F35; position: relative; top: 2px;"></div><b>Net changes</b></div>
              </div>              
            </div>

            <!-- Policies performance -->
            <div class="col-md-6 col-lg-4 col-custom">
              <div class="card graphdiv">
                <img src="../../../assets/img/employee/filter_emp.png" class="filter_emp" alt="img" />
                <h5>Policies performance</h5>
                <div [chart]="bar"></div>
                <button class="viewmorebtn">View More</button>
    
                <div style="display: flex; position: relative; left: -10px; top: 10px;">
                  <label style="font-size: 12px; margin-right: 5px;">As on Today</label>
                  <div class="toggle-switch">
                    <input type="checkbox" id="toggle" class="toggle-input">
                    <label for="toggle" class="toggle-label"></label>
                  </div>
                  <label style="font-size: 12px; margin-left: 5px;">Projected</label>            
                </div>
    
              </div>
            </div>

            <!-- Portfolio Analysis -->
            <div class="col-md-6 col-lg-4 col-custom">
              <div class="card graphdiv">
                <img src="../../../assets/img/employee/filter_emp.png" class="filter_emp" alt="img" />
                <h5 style="padding-bottom: 20px;">Portfolio Analysis</h5>
                <!-- <div class="text-center" style="font-size: 14px;"><b>Premium Spent</b></div> -->
                <div class="pie-chart-container" style="display: flex; margin: auto;">
                  <div [chart]="pieChart1"></div>
                  <div [chart]="pieChart2"></div>
                </div>
                <div style="display: flex;">
                  <div style="display: flex; margin-right: 20px;"><div style="width: 12px; margin-right: 4px; height: 12px; background: #173F35; position: relative; top: 2px;"></div><b>GMC</b></div>
                  <div style="display: flex; margin-right: 20px;"><div style="width: 12px; margin-right: 4px; height: 12px; background: #FFBF3F; position: relative; top: 2px;"></div><b>GPA</b></div>
                  <div style="display: flex;"><div style="width: 12px; margin-right: 4px; height: 12px; background: #F9423A; position: relative; top: 2px;"></div><b>GTL</b></div>
                </div>
              </div>
            </div>

            <!-- Survey’s Graph -->
            <div class="col-md-6 col-lg-4 col-custom">
              <div class="card graphdiv">
                <h5>Survey’s Graph</h5>
                <div style="margin: auto;" [chart]="pieChart3"></div>
                <button class="viewmorebtn">View More</button>
    
                <div style="display: flex; position: relative; top: 10px;">
                  <div style="display: flex; margin-right: 20px;"><div style="width: 12px; margin-right: 4px; height: 12px; background: #FFBF3F; position: relative; top: 2px;"></div><b>Excellent</b></div>
                  <div style="display: flex; margin-right: 20px;"><div style="width: 12px; margin-right: 4px; height: 12px; background: #FFBF3F; position: relative; top: 2px;"></div><b>Average</b></div>              
                </div>
                <div style="display: flex; position: relative; top: 10px;">
                  <div style="display: flex; margin-right: 39px;"><div style="width: 12px; margin-right: 4px; height: 12px; background: #173F35; position: relative; top: 2px;"></div><b>Good</b></div>
                  <div style="display: flex;"><div style="width: 12px; margin-right: 4px; height: 12px; background: #F9423A; position: relative; top: 2px;"></div><b>Poor</b></div>              
                </div>
    
              </div>
            </div>

            <!-- Advanced Claims Utilization Summary -->
            <div class="col-md-6 col-lg-4 col-custom">
              <div class="card graphdiv">
                <img src="../../../assets/img/employee/filter_emp.png" class="filter_emp" alt="img" />
                <h5 title="Advanced Claims Utilization Summary">Advanced Claims Utilization Summary</h5>
                <div class="radio-container mt-3">
                  <input type="radio" id="option1" name="option" value="option1" checked>
                  <label for="option1" class="ml-3"> <b>Amount</b></label>
    
                  <input type="radio" id="option2" name="option" value="option2" style="margin-left: 20px;">
                  <label for="option2"> <b>Percentage</b></label>
    
                </div> 
                <div class="chartdiv_bar2" [chart]="bar2"></div>
              </div>
            </div>
    
            <!-- Insurer Portfolio -->
            <div class="col-md-6 col-lg-4 col-custom">
              <div class="card graphdiv">
                <img src="../../../assets/img/employee/filter_emp.png" class="filter_emp" alt="img" />
                <h5>Insurer Portfolio</h5>
                <div class="chartdiv_bar" [chart]="bar1"></div>
                <button class="viewmorebtn">View More</button>
    
                <div style="display: flex; position: relative; left: -10px; top: 10px;">
                  <label style="font-size: 12px; margin-right: 5px;">As on Today</label>
                  <div class="toggle-switch">
                    <input type="checkbox" id="toggle" class="toggle-input">
                    <label for="toggle" class="toggle-label"></label>
                  </div>
                  <label style="font-size: 12px; margin-left: 5px;">Projected</label>            
                </div>
    
              </div>
            </div>
                
            <!-- Announcements -->
            <div class="col-md-6 col-lg-4 col-custom">
              <div class="card graphdiv" style="height: 206px;">
                <img src="../../../assets/img/employee/filter_emp.png" class="filter_emp" alt="img" />
                <h5>Announcements</h5>
                <ul style="overflow-y: auto; height: 110px;">
                  <li>Jan 2024- laughter session</li>            
                  <li>Feb 2024- mentle relaxation techniques etc</li>            
                  <li>March 2024- laughter session </li>            
                  <li>April 2024- mentle relaxation techniques etc</li>            
                  <li>Jan 2024- laughter session </li>            
                  <li>Feb 2024- mentle relaxation techniques etc</li>            
                  <li>March 2024- laughter session </li>
                </ul>          
                <button class="viewmorebtn">View More</button>
              </div>
            </div>

            <!-- Claims Summary -->
            <div class="col-md-6 col-lg-6 claimdiv">
              <div class="card graphdiv" style="height: 206px;">
                <img src="../../../assets/img/employee/filter_emp.png" class="filter_emp" alt="img" />
                <h5>Claims Summary</h5>
    
                <div class="radio-container">
                  <input type="radio" id="option1" name="option1" value="option1" checked>
                  <label for="option1" class="ml-3"> <b>GMC</b></label>
    
                  <input type="radio" id="option2" name="option1" value="option2" style="margin-left: 20px;">
                  <label for="option2"> <b>GPA</b></label>
    
                  <input type="radio" id="option3" name="option1" value="option3" style="margin-left: 20px;">
                  <label for="option3"> <b>GTL</b></label>
                </div>
    
                <table class="table table-sm table-bordered mt-3" style="table-layout: fixed;">
                  <thead>
                    <tr style="background: #015963; color: #fff;">
                      <td style="width: 50%;">Claims Summary</td>
                      <td style="width: 20%;">Count</td>
                      <td style="width: 30%;">Amount</td>
                    </tr>
                  </thead>
                  <tbody style="overflow-y: auto;">
                    <tr>
                      <td>Settled</td>
                      <td>10</td>
                      <td>₹ 12000</td>
                    </tr>
                    <tr style="background: #CCDFE1">
                      <td>Inprogress</td>
                      <td>20</td>
                      <td>₹ 10000</td>
                    </tr>
                    <tr>
                      <td>Rejected</td>
                      <td>5</td>
                      <td>₹ 50000</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>            

          </div>   

        </div>

        <!-- Right Section -->
        <div class="col-md-4">
          <div style="padding: 15px 15px; border-radius: 10px; background-color: #f8f8f8; padding-bottom: 50px;">
            
            <!-- Select -->
            <div class="row">

              <div class="col-sm-12">
                <div class="form-group">
                  <label class="bs-form-label" for="role">Select Policy lapsed</label>
                  <select class="form-select" id="role">
                    <option value="" selected>Select</option>
                    <!-- <option *ngFor="let contactrole of InfoArray_Role" [value]="contactrole.user_type_id">{{ contactrole.tag_name }}</option>     -->
                  </select>
                </div>
              </div>

              <div class="col-sm-12 mt-3">
                <span style="color: #f5620d; font-weight: bold; font-size: 12px;  float: right; ">
                  Policy End Date
                </span>
                <span style="color: #20B157; font-weight: bold; font-size: 12px;">
                  Policy Start Date
                </span>
                <div class="progress">
                  <div class="progress-bar p1" role="progressbar"
                    style="width: 33.33%; background-image: linear-gradient(to right,#00D63C, #78FF9E);"
                    attr.aria-valuenow="23" aria-valuemin="0" aria-valuemax="100"></div>
                  <div class="progress-bar p2" role="progressbar"
                    style="width: 33.33%; background-image: linear-gradient(to right,#f5e0a4, #FFC000);"
                    attr.aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                  <div class="progress-bar p2" role="progressbar"
                    style="width: 33.33%; background-image: linear-gradient(to right,#FF0000, #670000);"
                    attr.aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <span style="color: #f5620d; font-weight: bold; font-size: 12px; position: relative; float: right;">
                  01 Jan 2023
                </span>
                <span style="color: #20B157; font-weight: bold; font-size: 12px;  position: relative; top: 1px;">
                  01 Jan 2024
                </span>
              </div>
            </div>
            <!--  -->

            <hr class="hrclass">

            <!-- ₹ -->
            <div>
              <!-- <h5>cd balance as on today Entity wise</h5> -->
              <div style="background-color: #fff; padding: 10px; display: flex; border-radius: 20px;">
                <img src="../../../assets/img/employee/image_14.svg" alt="img" />
                <h2 class="textred"> ₹ 1,00,000</h2>
              </div>
  
              <div style="display: flex; width: 100%; margin-top: 10px; margin-left: 10px;">
                <div style="width: 50%;">
                  <span class="red-div" style="display: inline-block; position: relative; top: 2px;"></span> <span style="margin-left: 3px; font-size: 11px;">Less than threshold</span>                         
                </div>
                <div style="width: 50%; float: right;">
                  <span class="green-div" style="display: inline-block; position: relative; top: 2px;"></span> <span style="margin-left: 3px; font-size: 11px;">More than threshold</span>
                </div>
              </div>              
              
              <!-- <button class="viewmorebtn">View More</button> -->
            </div>
            <!--  -->

            <hr class="hrclass">

            <!-- Benefit benchmarking -->
            <div>
              <img src="../../../assets/img/employee/filter_emp.png" class="filter_emp_side" alt="img" />
              <h5>Benefit benchmarking</h5>            
              <!-- <div [chart]="benchmarkchart"></div> -->
              <img src="../../../assets/img/employee/benefitbench.png" alt="benefitbench" style="width: 100%; height: 200px;" />
              <button class="viewmorebtn_side mt-3">View More</button>
            </div>
            <!--  -->

            <hr class="hrclass" style="margin-top: 70px;">

            <!-- Well Being -->
            <div>
              <img src="../../../assets/img/employee/filter_emp.png" class="filter_emp_side" alt="img" />
              <h5>Well-being</h5>
  
              <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src="../../../assets/img/employee/slider_img1.png" class="d-block w-100 sliderimg" alt="Slide 1">
                  </div>
                  <div class="carousel-item">
                    <img src="../../../assets/img/employee/slider_img1.png" class="d-block w-100 sliderimg" alt="Slide 2">
                  </div>
                  <div class="carousel-item">
                    <img src="../../../assets/img/employee/slider_img1.png" class="d-block w-100 sliderimg" alt="Slide 3">
                  </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleSlidesOnly" role="button" data-slide="prev">
                  <span class="carousel-control-prev-icon" title="Previous" aria-hidden="true"></span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleSlidesOnly" role="button" data-slide="next">
                  <span class="carousel-control-next-icon" title="Next" aria-hidden="true"></span>
                </a>
                
              </div>              
  
            </div>
            <!--  -->            

          </div>
        </div>    
        
        <!-- Video -->
      <div class="col-sm-12" style="margin: 20px;">
        <!-- <h5>Broker's/service providers marketing material </h5> -->
        <iframe width="230" height="120" style="float: right; margin-top: -40px;" src="https://www.youtube.com/embed/tgbNymZ7vqY">
        </iframe>
        <!-- <button class="viewmorebtn_side">View More</button> -->
      </div>

      </div>      
      
    </div>
    <app-footer></app-footer>
  </div>

</div>
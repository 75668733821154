import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customized-solution',
  templateUrl: './customized-solution.component.html',
  styleUrls: ['./customized-solution.component.css']
})
export class CustomizedSolutionComponent implements OnInit {

  show = false;
  fullScreen = true;
  template = ``;
  
  constructor() { }

  ngOnInit(): void {
    this.show = true;
    this.fullScreen = true;
    this.template = ``
    setTimeout(() => {
        this.show = false
    }, 2000);
    $('html, body').animate({ scrollTop: 0 }, 'slow');
  }

}

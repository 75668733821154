<head>
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.0.8/css/all.css">
</head>
<!-- header -->
<header class="header-area">
    <div class="header-top second-header d-none d-md-block">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-3 col-md-3 d-none d-lg-block">
                </div>
                <div class="col-lg-2 col-md-8 d-none  d-md-block">
                    <div class="header-cta">
                        <ul>
                            <li>
                                <img src="../../assets/website/icon/email.png"
                                    style="width: 20px; margin-right: 10px;" />
                                <span style="font-size: 12px;">info&#64;neodigit.in</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-5 col-md-3 d-none d-lg-block">
                    <div class="header-social text-right">
                        <span>
                            <a href="#" title="Facebook"><i class="fab fa-facebook"></i></a>
                            <a href="#" title="Twitter"><i class="fab fa-twitter"></i></a>
                            <a href="#" title="LinkedIn"><i class="fab fa-linkedin-in"></i></a>                            
                        </span>
                        <!--  /social media icon redux -->                        
                    </div>                    
                </div>
                <div class="col-lg-2 col-md-8 d-none  d-md-block">
                    <a routerLink="/login" class="top-btn" href="#" style=" width: 136px;">Sign In <i class="fas fa-chevron-right"></i></a>                        
                </div>                

            </div>
        </div>
    </div>
    <div id="header-sticky" class="menu-area">
        <div class="container">
            <div class="second-menu">
                <div class="row align-items-center">
                    <div class="col-xl-2 col-lg-2">
                        <div class="logo">
                            <a routerLink="/home" href="#"><img src="../../assets/homelogo.png" alt="logo"></a>
                        </div>
                    </div>
                    <div class="col-xl-10 col-lg-8">
                        <div class="main-menu text-right pr-15">
                            <nav id="mobile-menu">
                                <ul>
                                    <!-- Standalone Home Link -->
                                    <li>
                                        <a routerLink="/home" href="#">Home</a>
                                    </li>
                                    
                                    <!-- Dropdown for About Us with sub-items -->
                                    <li class="has-sub">
                                        <a href="#">About Us</a>
                                        <ul class="sub-menu">
                                            <li><a routerLink="/about-company" href="#">About Company</a></li>
                                            <li><a routerLink="/leadership-team" href="#">Leadership Team</a></li>
                                            <li><a routerLink="/why-neodigit" href="#">Why NeoDigit?</a></li>
                                            <li><a routerLink="/vision-mission" href="#">Vision & Mission</a></li>
                                        </ul>
                                    </li>
                                    
                                    <!-- Dropdown for Our Services with sub-items -->
                                    <li class="has-sub">
                                        <a href="#">Our Services</a>
                                        <ul class="sub-menu">
                                            <li><a routerLink="/eb-portal" href="#">EB Portal</a></li>
                                            <li><a routerLink="/health-benefits-tool" href="#">Health & Benefits Tool</a></li>
                                            <li><a routerLink="/benefits-consulting" href="#">Benefits Consulting</a></li>
                                            <li><a routerLink="/automation" href="#">Automation (Service & Operations)</a></li>
                                            <li><a routerLink="/communication" href="#">Communication</a></li>
                                            <li><a routerLink="/wellbeing" href="#">Wellbeing</a></li>
                                            <li><a routerLink="/affinity" href="#">Affinity</a></li>
                                            <li><a routerLink="/customized-solution" href="#">Customized Solution</a></li>
                                        </ul>
                                    </li>
                    
                                    <!-- Standalone Schedule a Demo Link -->
                                    <li>
                                        <a routerLink="/schedule-demo" href="#">Schedule a Demo</a>
                                    </li>
                    
                                    <!-- Standalone Contact Us Link -->
                                    <li>
                                        <a routerLink="/contact-us" href="#">Contact Us</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                                      
                    <div class="col-12">
                        <div class="mobile-menu"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- header-end -->

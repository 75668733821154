<!-- header -->
<app-web-menu-bar></app-web-menu-bar>
<!-- header-end -->

<!-- main-area -->
<main style="background-color: #fff !important;">
    <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template"
        style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>

    <div class="main_title">
        <span>EB Portal</span>
    </div>

    <div class="my-3 text-center">
        <h3>Benefits Portal Ecosystem – Your One-Stop Destination for Workforce Management</h3>
        <p>Our benefits portal ecosystem are designed to provide services to all stakeholders, regardless of the channel: insurers, brokers, corporate agents, or employers. solutions.</p>           
    </div>

    <!-- Key Features Section -->
        <section class="features-section">
            <div class="container">                
                <div class="row">                    
                    <div class="col-md-4">
                        <div class="card shadow p-3 mb-5 rounded" style="background-color: #ffebee;"> <!-- Light pastel red -->
                            <div class="card-body">
                                <h5 class="card-title">Easy Benefits Information and Enrolment</h5>
                                <p class="card-text">Employees can effortlessly browse detailed benefits options and
                                    enroll in plans that suit their needs, simplifying the selection process.</p>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Card 2 -->
                    <div class="col-md-4">
                        <div class="card shadow p-3 mb-5 rounded" style="background-color: #e8f5e9;"> <!-- Light pastel green -->
                            <div class="card-body">
                                <h5 class="card-title">Streamlined Claims and Policy Management</h5>
                                <p class="card-text">Centralized tools for managing and tracking claims, enabling
                                    employees to submit claims, check their status, and view policy details all in one
                                    place.</p>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Card 3 -->
                    <div class="col-md-4">
                        <div class="card shadow p-3 mb-5 rounded" style="background-color: #e3f2fd;"> <!-- Light pastel blue -->
                            <div class="card-body">
                                <h5 class="card-title">Comprehensive Well-being and Self-Care Resources</h5>
                                <p class="card-text">A wealth of resources supporting employees' health, including
                                    health tips, fitness programs, mental health support, and preventive care
                                    information.</p>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Card 4 -->
                    <div class="col-md-4">
                        <div class="card shadow p-3 mb-5 rounded" style="background-color: #fff3e0;"> <!-- Light pastel orange -->
                            <div class="card-body">
                                <h5 class="card-title">Tailored Support and Guidance</h5>
                                <p class="card-text">Personalized assistance for employees navigating their benefits,
                                    with dedicated support available for inquiries and issue resolution.</p>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Card 5 -->
                    <div class="col-md-4">
                        <div class="card shadow p-3 mb-5 rounded" style="background-color: #f3e5f5;"> <!-- Light pastel purple -->
                            <div class="card-body">
                                <h5 class="card-title">Surveys and Feedback</h5>
                                <p class="card-text">Gather insights from employees to enhance benefits offerings and
                                    overall satisfaction.</p>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Card 6 -->
                    <div class="col-md-4">
                        <div class="card shadow p-3 mb-5 rounded" style="background-color: #f9fbe7;"> <!-- Light pastel yellow -->
                            <div class="card-body">
                                <h5 class="card-title">Benefit Benchmarking</h5>
                                <p class="card-text">Compare benefits against industry standards to ensure competitive
                                    offerings that meet employee needs.</p>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Card 7 -->
                    <div class="col-md-4">
                        <div class="card shadow p-3 mb-5 rounded" style="background-color: #fce4ec;"> <!-- Light pastel pink -->
                            <div class="card-body">
                                <h5 class="card-title">Claims Utilization Reports</h5>
                                <p class="card-text">Access detailed reports on claims utilization to inform
                                    decision-making and optimize benefits programs.</p>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Card 8 -->
                    <div class="col-md-4">
                        <div class="card shadow p-3 mb-5 rounded" style="background-color: #e0f7fa;"> <!-- Light pastel cyan -->
                            <div class="card-body">
                                <h5 class="card-title">Customizable Dashboard</h5>
                                <p class="card-text">Employees can personalize their dashboard to prioritize relevant
                                    information and tools for a tailored experience.</p>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Card 9 -->
                    <div class="col-md-4">
                        <div class="card shadow p-3 mb-5 rounded" style="background-color: #fff9c4;"> <!-- Light pastel lime -->
                            <div class="card-body">
                                <h5 class="card-title">Mobile Optimization</h5>
                                <p class="card-text">Fully optimized for mobile devices, enabling employees to manage
                                    their benefits anytime, anywhere.</p>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Card 10 -->
                    <div class="col-md-4">
                        <div class="card shadow p-3 mb-5 rounded" style="background-color: #ede7f6;"> <!-- Light pastel lavender -->
                            <div class="card-body">
                                <h5 class="card-title">Secure Login and Data Protection</h5>
                                <p class="card-text">Robust security measures to protect personal and sensitive
                                    information, ensuring safe and trusted access.</p>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Card 11 -->
                    <div class="col-md-4">
                        <div class="card shadow p-3 mb-5 rounded" style="background-color: #fff3e0;"> <!-- Light pastel yellow -->
                            <div class="card-body">
                                <h5 class="card-title">Real-Time Updates and Notifications</h5>
                                <p class="card-text">Stay informed with timely alerts about important changes,
                                    deadlines, and new benefits offerings.</p>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </section>

</main>
<!-- main-area-end -->

<!-- footer -->
<app-web-footer></app-web-footer>
<!-- footer-end -->
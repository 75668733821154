<!-- header -->
<app-web-menu-bar></app-web-menu-bar>
<!-- header-end -->

<!-- main-area -->
<main style="background-color: #fff !important;">
    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        
        
    <div class="main_title">
        <span>Health Benefits Tool</span>
    </div>         

    <div class="container mt-3">
        <div class="accordion" id="accordionExample">
            <!-- Section A: Benefits Enrolment and Management Portal -->
<div class="card">
    <div class="card-header" id="headingA">
        <h2 class="mb-0">
            <button class="btn btn-link accordion-button" type="button" data-toggle="collapse" data-target="#collapseA" aria-expanded="true" aria-controls="collapseA">
                Benefits Enrolment and Management Portal
            </button>
        </h2>
    </div>
    <div id="collapseA" class="collapse show" aria-labelledby="headingA" data-parent="#accordionExample">
        <div class="card-body">
            <p>Our Insurance Enrolment Portal provides a streamlined, user-friendly solution for capturing all necessary employee insurance information.</p>
            <h4 class="my-3">Key Features</h4>
            <ul class="features-list">
                <li>Centralized Information Management</li>
                <li>User-Friendly Interface</li>
                <li>Real-Time Data Updates</li>
                <li>Secure and Compliant</li>
                <li>Automated Workflows</li>
                <li>Comprehensive Reporting Tools</li>
                <li>Mobile Accessibility</li>
            </ul>
            <div class="highlight">
                <h4 class="my-3">Benefits of Our Portal</h4>
                <ul class="benefits-list">
                    <li>Enhanced Employee Experience</li>
                    <li>Increased Efficiency for HR Teams</li>
                    <li>Data Accuracy and Integrity</li>
                    <li>Scalability</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<!-- Section B: OPD & Flex Benefits -->
<div class="card">
    <div class="card-header" id="headingB">
        <h2 class="mb-0">
            <button class="btn btn-link accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapseB" aria-expanded="false" aria-controls="collapseB">
                OPD & Flex Benefits
            </button>
        </h2>
    </div>
    <div id="collapseB" class="collapse" aria-labelledby="headingB" data-parent="#accordionExample">
        <div class="card-body">
            <p>Our OPD Insurance & Flex solutions redefine employee benefits through a personalized, flexible approach.</p>
            <h4 class="my-3">Key Features</h4>
            <ul class="features-list">
                <li>Diverse Options: Choose from a comprehensive range of insurance options, including Dental, Vision, Life, and Disability.</li>
                <li>Customizable Packages: Employees can allocate benefit points to create a tailored benefits package that meets their individual needs.</li>
                <li>Increased Flexibility: More choices lead to greater flexibility compared to traditional plans, ensuring employees feel empowered.</li>
            </ul>
        </div>
    </div>
</div>

<!-- Section C: Intelligent Claim Management System -->
<div class="card">
    <div class="card-header" id="headingC">
        <h2 class="mb-0">
            <button class="btn btn-link accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapseC" aria-expanded="false" aria-controls="collapseC">
                Intelligent Claim Management System
            </button>
        </h2>
    </div>
    <div id="collapseC" class="collapse" aria-labelledby="headingC" data-parent="#accordionExample">
        <div class="card-body">
            <p>Our claims management software empowers your team to make informed, data-driven decisions, enhancing efficiency and effectiveness.</p>
            <h4 class="my-3">Key Features and Benefits</h4>
            <ul class="features-list">
                <li>Advanced Data Collection</li>
                <li>Robust Authentication Mechanisms</li>
                <li>Streamlined Submission Processes</li>
                <li>Increased Efficiency</li>
                <li>Improved Accuracy</li>
                <li>Better Client Relationships</li>
                <li>Integrated with insurers, TPAs, or OPD providers</li>
            </ul>
        </div>
    </div>
</div>

        </div>        
    </div>
    
    
</main>
<!-- main-area-end -->
 
<!-- footer -->
<app-web-footer></app-web-footer>
<!-- footer-end -->


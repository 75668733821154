<!-- header -->
<app-web-menu-bar></app-web-menu-bar>
<!-- header-end -->

<!-- main-area -->
<main style="background-color: #fff !important;">
    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        
       
    <div class="main_title">
        <span>Why NEoDigit?</span>
    </div>

    <div class="container my-5">
        <div class="row align-items-center">
            
            <div class="col-lg-6 col-md-12 col-sm-12 my-5">
                <div class="about-content s-about-content pl-30">
                    <div class="section-title mb-2">
                        <span><b>Why NeoDigit?  - Optimize Your Budget with Us!</b></span>
                    </div>
                    <p>Say goodbye to skyrocketing technology costs! At NeoDigit, we specialize in significantly cutting your monthly expenses on maintaining and upgrading your tech infrastructure. Our innovative solutions and flexible pricing models ensure you stay ahead of the curve without stretching your budget.</p>

                    <p>With NeoDigit, you can</p>
                    <ul>
                        <li class="highlight"><b>Reduce Operational Expenses:</b> Slash your technology costs.</li>
                        <li class="highlight"><b>Avoid Costly Upgrades:</b> Eliminate frequent, expensive updates and maintenance.</li>
                        <li class="highlight"><b>Scale Seamlessly:</b> Adjust your tech needs without financial strain.</li>
                        <li class="highlight"><b>Focus on Growth:</b> Concentrate on expanding your business, not on budget constraints.</li>
                    </ul>                        

                    <p>Discover how NeoDigit can streamline your technology requirements & expenses and enhance your bottom line.
                        Contact us today to learn more! - <u style="color:blue">info&#64;neodigit.in</u>
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="s-about-img p-relative">
                    <img src="../../../assets/website/why_us.jpg" style="margin: auto; display: block; width: 100%;" alt="img">                        
                </div>
            </div>
          
        </div>
    </div>    
    
</main>
<!-- main-area-end -->
 
<!-- footer -->
<app-web-footer></app-web-footer>
<!-- footer-end -->

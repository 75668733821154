<!-- header -->
<app-web-menu-bar></app-web-menu-bar>
<!-- header-end -->

<!-- main-area -->
<main style="background-color: #fff !important;">
    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        
       
    <div class="main_title">
        <span>Leadership Team</span>
    </div>

    <div class="container my-5">
        <div class="row align-items-center">
            
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="about-content s-about-content pl-30">
                   
                    <div class="leadership">
                        <div class="member">
                            <img src="../../../assets/img/common icons/user.png" alt="Giriraj Singh">
                            <h3><b>Giriraj Singh</b></h3>
                            <p><strong>Giriraj Singh</strong> is an IIMK Alumni & Visionary Insurance Leader with over 21+ years of experience, recognized for his expertise, innovative approach, and strategic leadership. His illustrious career spans esteemed organizations, including Marsh, Prudent, WTW, Narayan Health, Samvedana Hospital, and Prakash Hospital, where he has driven growth, expansion, implemented cutting-edge technology solutions, enhanced customer satisfaction and experience.</p>
                            <p>With a deep understanding of insurance products, including benefits consulting and insurance analytics, he excels in innovative solutions for clients, strategic leadership, and forward-thinking approach. As a trailblazer in the industry, his commitment to innovation, strategic leadership, and thought leadership continues to shape the future of insurance, driving progress and excellence.</p>
                        </div>
                        
                        <div class="team-title">
                            <h2 style="position: relative;
                            top: 350px;
                            font-weight: bold;">Leadership</h2>
                        </div>
                        
                        <div class="member">
                            <img src="../../../assets/img/common icons/user.png" alt="Durgesh Kabra">
                            <h3><b>Durgesh Kabra</b></h3>
                            <p><strong>Durgesh Kabra</strong> is a highly accomplished technocrat with 15+ years of experience in software design, development, and leadership. Proven expertise in software design and development, system analysis, planning, execution, performance tuning, testing, and release/build management, training and mentoring, and customer relationship management.</p>
                            <p class="highlight"><strong>Programming languages:</strong> Java/J2EE, Spring/Hibernate, SOA/Restful Web services</p>
                            <p class="highlight"><strong>Frameworks:</strong> JSF, Struts, ATG</p>
                            <p class="highlight"><strong>Web development:</strong> HTML, XHTML, DHTML, XML, XSLT, CSS, JSP, AJAX, DOJO, JSON, JavaScript</p>
                            <p class="highlight"><strong>Data management:</strong> XML parsing (DOM and SAX), XSLT transformation, SQL, PL/SQL, Oracle, MySQL</p>
                            <p class="highlight"><strong>Application servers:</strong> Apache Tomcat, JBoss Server, WebSphere, WebLogic</p>   
                            <p>Durgesh Kabra is a seasoned technical lead with a strong track record of delivering complex projects and leading high-performing teams.</p>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>    
    
</main>
<!-- main-area-end -->
 
<!-- footer -->
<app-web-footer></app-web-footer>
<!-- footer-end -->

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import { Subject } from 'rxjs';
import { CommonService } from '../../_services/common.service';
import { numberFormat } from 'highcharts';
@Component({
  selector: 'app-flex-configurator-dashboard',
  templateUrl: './flex-configurator-dashboard.component.html',
  styleUrls: ['./flex-configurator-dashboard.component.css']
})
export class FlexConfiguratorDashboardComponent implements OnInit {
  [x: string]: any;

  show = false;
  fullScreen = true;
  template = ``;

  rightModal: any = "none";
  Coverages: any = "none";
  OPDBenefits: any = "none";

  addFlag: any = 0;

  CoveragesArray: any = [
    { title: "OPD Benefits Starting at", price: "₹ 27,838", img_URL: "coverage_1.png" },
    { title: "Maternity Benefits Starting at", price: "₹ 27,838", img_URL: "coverage_2.png" },
    { title: "Rewards & Benefits Starting at", price: "₹ 27,838", img_URL: "coverage_3.png" },
    { title: "OPD Benefits Starting at", price: "₹ 27,838", img_URL: "coverage_1.png" },
    { title: "Maternity Benefits Starting at", price: "₹ 27,838", img_URL: "coverage_2.png" }
  ]

  OPDarray: any = [
    { col_1: '24x7 online GP consult', col_2: 'Unlimited', col_3: 'Unlimited', col_4: 'Unlimited', col_5: 'Unlimited' },
    { col_1: 'Specialist Consults (Virtual)', col_2: '2', col_3: '3', col_4: '5', col_5: '3' },
    { col_1: 'Vision Cover ', col_2: 'Vision Cover ', col_3: 'Cover - Upto ₹ 5000 per Family Premium - ₹ 9754 Inclusion- ₹ 5,000/- (Capping if Reimbursement as 50% of above limit) Exclusion-Sunglasses, cost of Frames, cosmetic lenses and cosmetic procedures', col_4: 'Cover - Upto ₹ 5000 per Family Premium - ₹ 9754 Inclusion- ₹ 5,000/- (Capping if Reimbursement as 50% of above limit) Exclusion-Sunglasses, cost of Frames, cosmetic lenses and cosmetic procedures', col_5: 'Cover - Upto ₹ 5000 per Family Premium - ₹ 9754 Inclusion- ₹ 5,000/- (Capping if Reimbursement as 50% of above limit) Exclusion-Sunglasses, cost of Frames, cosmetic lenses and cosmetic procedures' },
    { col_1: 'Dental Cover', col_2: '0', col_3: 'Cover - Upto ₹ 5000 per Family Premium-  ₹ 9754 Inclusion- ₹ 8,000/ Exclusion-Any cosmetic procedure like scaling, polishing are not covered.', col_4: 'Cover - Upto ₹ 5000 per Family Premium-  ₹ 9754 Inclusion- ₹ 8,000/ Exclusion-Any cosmetic procedure like scaling, polishing are not covered.', col_5: 'Cover - Upto ₹ 5000 per Family Premium-  ₹ 9754 Inclusion- ₹ 8,000/ Exclusion-Any cosmetic procedure like scaling, polishing are not covered.' },
    { col_1: 'Health Check-up including Preventive', col_2: 'Upto ₹ 5,000 per Family', col_3: 'Upto ₹ 5,000 per Family', col_4: 'Upto ₹ 5,000 per Family', col_5: 'Upto ₹ 5,000 per Family' },
    { col_1: 'Children Vaccination Cover', col_2: '0', col_3: 'Upto ₹ 2000 per Family', col_4: '0', col_5: '0' },
    { col_1: 'Diagnostic Cover', col_2: 'Upto ₹ 2,000 per Family', col_3: 'Upto ₹ 2,000 per Family', col_4: 'Upto ₹ 2,000 per Family', col_5: 'Upto ₹ 2,000 per Family' },
    { col_1: 'In-Person Consultation', col_2: '3', col_3: '3', col_4: '3', col_5: '3' },
    { col_1: 'Maternity care', col_2: 'Nutrition counselling & Health content', col_3: 'Gynaecologist tele consult, Nutrition counselling & Health content', col_4: 'Gynaecologist tele consult, Nutrition counselling & Health content', col_5: 'Gynaecologist tele consult, Nutrition counselling & Health content' },
    { col_1: 'Inclinic Consultation', col_2: 'Inclusion-Allopathic Consultation only Exclusion-Non- Allopathic consultations', col_3: 'Inclusion-Allopathic Consultation only Exclusion-Non- Allopathic consultations', col_4: 'Inclusion-Allopathic Consultation only Exclusion-Non- Allopathic consultations', col_5: 'Inclusion-Allopathic Consultation only Exclusion-Non- Allopathic consultations' },
    { col_1: '0', col_2: 'Add to Compare', col_3: 'Add to Compare', col_4: 'Add to Compare', col_5: 'Add to Compare' },
    { col_1: '0', col_2: 'Selected', col_3: 'Select', col_4: 'Select', col_5: 'Select' },
    { col_1: '0', col_2: 'Watch Plan Video', col_3: 'Watch Plan Video', col_4: 'Watch Plan Video', col_5: 'Watch Plan Video' }
  ]

  gmc_relation: any = [];
  gmc_obj: any = {
    "name": "Group Mediclaim Policy",
    "relation": "Self,Spouse,Mother,Father",
    "cover": "500000",
    "premium": "9992",
    "is_premium_tax": "1"
  };

  gpa_relation: any = [];
  gpa_obj: any = {
    "name": "Voluntary Additional Parental Policy",
    "relation": "Self,Spouse,Mother,Father",
    "cover": "500000",
    "premium": "9992",
    "is_premium_tax": "1"
  };

  gtl_relation: any = [];
  gtl_obj: any = {
    "name": "Voluntary Additional Parental Policy",
    "relation": "Self,Spouse,Mother,Father",
    "cover": "500000",
    "premium": "9992",
    "is_premium_tax": "1"
  };

  v_topup_obj: any = {
    "name": "Voluntary Topup Policy",
    "relation": "Self,Spouse,Mother,Father",
    "cover": "500000",
    "premium": "9992",
    "is_premium_tax": "1"
  };

  v_addon_obj: any = {
    "name": "Add On Benefits Base Policy",
    "relation": "OPD Benefits (Floater)",
    "cover": "500000",
    "premium": "9992",
    "is_premium_tax": "1"
  };

  ancillaryData: any = [];
  partner_id: any;

  gmcbasearray: any = [];
  gmcadditionalarray: any = [];
  gpabasearray: any = [];
  gpaadditionalarray: any = [];
  gtlbasearray: any = [];
  gtladditionalarray: any = [];

  /** VOLUNTARY PLANS **/
  gmcvoluntaryplans: any = [];
  gpavoluntaryplans: any = [];
  gtlvoluntaryplans: any = [];

  /******* Enhance PLAN  */
  gmcEnhancePlan : any = [];
  gpaEnhancePlan : any = [];
  gtlEnhancePlan : any = [];

  gmc_you_pay: any = 0
  gmc_employer_pay: any = 0;
  gmc_total_youpay_premium: any = 0;
  gmc_total_employer_pay_premium: any = 0;

  gpa_you_pay: any = 0
  gpa_employer_pay: any = 0;
  gpa_total_youpay_premium: any = 0;
  gpa_total_employer_pay_premium: any = 0;

  gtl_you_pay: any = 0
  gtl_employer_pay: any = 0;
  gtl_total_youpay_premium: any = 0;
  gtl_total_employer_pay_premium: any = 0;

  showDashboard: any;

  gmc_flex_plan:any = [];
  gmcFlexPlan:any = [];

  gpa_flex_plan:any = [];
  gpaFlexPlan:any = [];

  gtl_flex_plan:any = [];
  gtlFlexPlan:any = [];

  gmc_disclaimer:any;
  gpa_disclaimer:any;
  gtl_disclaimer:any;

  gmc_feature_arr:any = [];

  plan_Array:any = [];
  pricingarr:any = [];  
  TableArray:any = [];
  keys: string[] = []; // Define the keys array
  data: any[] = []; // Define the data array
  policyname:any;
  plan_array:any = [];
  subPlanArray:any = [];
  premium_Array:any = [];
  masterids_Array:any = [];
  basedata_length:any;
  policyname_color:any;
  page_title:any
  page_desc:any;
  gotoflag:any;
  plan_id:any;
  flex_benefit_id:any;
  flex_plan_idfk:any;

  gmccolorCodes:any = ['#fff', '#c4d6d8', '#93b7bb', '#62979d', '#317880', '#005963'];
  gpacolorCodes:any = ['#fff', '#c4e6e7', '#93d8da', '#62c9cc', '#31bbbf', '#00acb1'];
  gtlcolorCodes:any = ['#fff', '#ecd5d7', '#e3b4b9', '#da949a', '#d1737c', '#c8535e'];

  constructor(
    private fb: FormBuilder,
    private dataservice: Service,
    private route: Router,
    public commonservice: CommonService,

  ) { }

  ngOnInit(): void {
    this.gmc_relation = this.gmc_obj.relation.split(',');
    this.gpa_relation = this.gpa_obj.relation.split(',');
    this.gtl_relation = this.gtl_obj.relation.split(',');
    this.fetchtotalcostsummary();
    this.fetchAncillaryPlan();
    this.getemployeepolicies();

    this.show = true;

    setTimeout(() => {
      if (this.gmcbasearray.length > 0) {
        $('#Add-tab').addClass('active');
        $('#Add').addClass('active show');
      } else if (this.gpabasearray.length > 0) {
        $('#Remove-tab').addClass('active');
        $('#Remove').addClass('active show');
      } else if (this.gtlbasearray.length > 0) {
        $('#Corrections-tab').addClass('active');
        $('#Corrections').addClass('active show');
      }

      this.show = false;

    }, 2000);
      
  }
 
  fetchtotalcostsummary() {
    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id')
    };

    this.dataservice.getInfo(postdata)
      .subscribe((result) => {
        if (result.status == true) {

          this.gmc_disclaimer = result.data.disclaimer.gmc_disclaimer;
          this.gpa_disclaimer = result.data.disclaimer.gpa_disclaimer;
          this.gtl_disclaimer = result.data.disclaimer.gtl_disclaimer;

          this.gmcbasearray = result.data.base_plans.gmc;
          this.gmcadditionalarray = result.data.additional_featurs.gmc;

          this.gpabasearray = result.data.base_plans.gpa;
          this.gpaadditionalarray = result.data.additional_featurs.gpa;

          this.gtlbasearray = result.data.base_plans.gtl;
          this.gtladditionalarray = result.data.additional_featurs.gtl;

          this.gmcvoluntaryplans = result.data.voluntary_plans.gmc;
          this.gpavoluntaryplans = result.data.voluntary_plans.gpa;
          this.gtlvoluntaryplans = result.data.voluntary_plans.gtl;

          this.gmcEnhancePlan = result.data.enhance_plan.gmc;
          this.gpaEnhancePlan = result.data.enhance_plan.gpa;
          this.gtlEnhancePlan = result.data.enhance_plan.gtl;

          this.gmc_total_youpay_premium = result.data.gmc_total_youpay_premium;
          this.gmc_total_employer_pay_premium = result.data.gmc_total_employer_pay_premium;
          this.gmc_you_pay = numberFormat(parseInt(result.data.gmc_total_youpay_premium) / (parseFloat(result.data.gmc_total_employer_pay_premium) + parseFloat(result.data.gmc_total_youpay_premium)) * 100, 0);
          this.gmc_employer_pay = numberFormat(parseInt(result.data.gmc_total_employer_pay_premium) / (parseFloat(result.data.gmc_total_employer_pay_premium) + parseFloat(result.data.gmc_total_youpay_premium)) * 100, 0);

          this.gpa_total_youpay_premium = result.data.gpa_total_youpay_premium;
          this.gpa_total_employer_pay_premium = result.data.gpa_total_employer_pay_premium;

          this.gpa_you_pay = numberFormat((parseInt(result.data.gpa_total_youpay_premium) / (parseFloat(result.data.gpa_total_employer_pay_premium) + parseFloat(result.data.gpa_total_youpay_premium))) * 100, 0);
          this.gpa_employer_pay = numberFormat(parseInt(result.data.gpa_total_employer_pay_premium) / (parseFloat(result.data.gpa_total_employer_pay_premium) + parseFloat(result.data.gpa_total_youpay_premium)) * 100, 0);

          this.gtl_total_youpay_premium = result.data.gtl_total_youpay_premium;
          this.gtl_total_employer_pay_premium = result.data.gtl_total_employer_pay_premium;
          this.gtl_you_pay = numberFormat(parseInt(result.data.gtl_total_youpay_premium) / (parseFloat(result.data.gtl_total_employer_pay_premium) + parseFloat(result.data.gtl_total_youpay_premium)) * 100, 0);
          this.gtl_employer_pay = numberFormat(parseInt(result.data.gtl_total_employer_pay_premium) / (parseFloat(result.data.gtl_total_employer_pay_premium) + parseFloat(result.data.gtl_total_youpay_premium)) * 100, 0);

          this.showDashboard = result.data.show_dashboard;
          localStorage.setItem('show_dashboard', this.showDashboard);

          if(this.showDashboard === 1){
            this.route.navigate(['/flex-plan']);
          }else{
            this.route.navigate(['/flex-configurator-dashboard']);
          }
          
          var combinedArray_gmc = [].concat.apply([], result.data.flex_basic_data.gmc);
          for (let i = 0; i < combinedArray_gmc.length; i += 3) {
            this.gmc_flex_plan.push(combinedArray_gmc.slice(i, i + 3));
          }

          var combinedArray_gpa = [].concat.apply([], result.data.flex_basic_data.gpa);
          for (let i = 0; i < combinedArray_gpa.length; i += 3) {
            this.gpaFlexPlan.push(combinedArray_gpa.slice(i, i + 3));
          }

          var combinedArray_gtl = [].concat.apply([], result.data.flex_basic_data.gtl);
          for (let i = 0; i < combinedArray_gtl.length; i += 3) {
            this.gtlFlexPlan.push(combinedArray_gtl.slice(i, i + 3));
          }

          this.gmcFlexPlan = result.data.flex_basic_data.gmc;

          this.gpa_flex_plan = result.data.flex_basic_data.gpa;

          this.gtl_flex_plan = result.data.flex_basic_data.gtl;

          // console.log('-------------------',this.gmc_flex_plan);

          // var combinedArray_gpa = [].concat.apply([], result.data.flex_data.gpa);
          // for (let i = 0; i < combinedArray_gpa.length; i += 3) {
          //   this.gpa_flex_plan.push(combinedArray_gpa.slice(i, i + 3));
          // }

          // var combinedArray_gtl = [].concat.apply([], result.data.flex_data.gtl);
          // for (let i = 0; i < combinedArray_gtl.length; i += 3) {
          //   this.gtl_flex_plan.push(combinedArray_gtl.slice(i, i + 3));
          // }         

        }
      }, (error) => {

        if (error.error.message == 'Unauthenticated.') {
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
      });
  }

  fetchAncillaryPlan(){

    this.addFlag = 1;
    localStorage.setItem('addFlag', this.addFlag);
    
    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id')
    };

    this.dataservice.getInfoAncillary(postdata)
      .subscribe((result) => {                  
        if (result.status == true) {
          // this.ancillaryData = result.data;

          const combinedArray = [].concat.apply([], result.data);
          this.ancillaryData = []; // Initialize ancillaryData as an empty array

          // Chunk combinedArray into arrays of three items each
          for (let i = 0; i < combinedArray.length; i += 5) {
            this.ancillaryData.push(combinedArray.slice(i, i + 5));
          }
        }

      }, (error) => {         
        
        if(error.error.message == 'Unauthenticated.'){
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
    });
  }

  openmodal() {
    this.rightModal = "block";
  }

  openCoverages() {
    this.Coverages = "block";
  }

  openOPDBenefits(flex_benefit_id:any, policy_id:any, policyname_color:any) {
    this.OPDBenefits = "block";

    this.policyname_color = policyname_color;

    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'),
      "policy_id": policy_id,
      "flex_benefit_id": flex_benefit_id
    };    

    this.dataservice.getflexplanbypolicydashboard(postdata)
      .subscribe((result) => {
        if (result.status == true) {

          this.TableArray = [];
          this.plan_Array = [];

          for (let i = 0; i < result.data.alldata.length; i++) {
            let obj = result.data.alldata[i];
            // if (obj.show === 0 && obj.data === 0) {
              
              this.page_title = obj.benefit_name;
              this.page_desc = obj.benefit_description;
              this.TableArray = obj.table_rows; 
              this.disclaimer = obj.plan_disclaimer;  
              this.mandatory_type = obj.mandatory_type;  
              this.subPlanArray = obj.subplan_id;
              this.premium_Array = obj.premium_Array;   
              this.masterids_Array = obj.masterids;
              this.plan_id = obj.plan_id;
              this.flex_benefit_id = obj.flex_benefit_id;
              this.flex_plan_idfk = obj.flex_plan_idfk;
                              
              /**********/
              for(var j = 0; j < obj.plan_Array.length; j++){
                this.plan_Array.push({plan_name:obj.plan_Array[j], bgcolor:"", color: ""});                           
              }
      
              // console.log("TableArray", this.TableArray);
              // console.log("plan_Array",this.plan_Array);
      
              let tabColors = this.assignBackgroundColors(this.plan_Array);  
              // console.log(tabColors);
      
              for(var k = 0; k < this.plan_Array.length; k++){        
                this.plan_Array[k]['bgcolor'] = tabColors[k];
                if(tabColors[k] == '#fff'){
                  this.plan_Array[k]['color'] = '#000';
                }else{
                  this.plan_Array[k]['color'] = '#fff';
                }          
              }          
                  
              console.log(this.masterids_Array);

              for (var h = 0; h < this.masterids_Array.length; h++) {
                console.log(obj.is_selected_planid, this.masterids_Array[h]);
                if (obj.is_selected_planid == this.masterids_Array[h]) {
                    let a: any = '#plan_' + h;
                    // console.log('-----',h);
                    let cnt:any = h;
                    setTimeout(() => {                  
                        $(a).prop('checked', true); // Set checkbox state using jQuery                  
                        this.handleCheckbox(cnt, 1);
                    }, 1000);
                }
              }      
              break;
            // }        
          }

        }
      });

  }

  closealert() {
    this.rightModal = "none";
    this.Coverages = "none";
    this.OPDBenefits = "none";
  }

  hideAndExpand_gmc(e: any) {

    var displayValue = $('#colSm5_gmc').css('display');
    if (displayValue === 'block') {
      $('#colSm5_gmc').slideUp('slow', function () {
        $('#colSm7_gmc').removeClass('col-sm-7').addClass('col-sm-12');
        $('#colSm5_gmc').css('display', 'none');
        $('#rightbtn_gmc').removeClass('rightbtn_gmc').addClass('fliprightbtn_gmc');
        $('.wrarrow_gmc').css('transform', 'rotateY(180deg)');
      });
    } else {
      $('#colSm5_gmc').slideUp('slow', function () {
        $('#colSm7_gmc').removeClass('col-sm-12').addClass('col-sm-7');
        $('#colSm5_gmc').css('display', 'block');
        $('#rightbtn_gmc').removeClass('fliprightbtn_gmc').addClass('rightbtn_gmc');
        $('.wrarrow_gmc').css('transform', 'rotateY(0deg)');
      });
    }

    e.stopPropagation();
    console.log("Button 1 clicked");

  }

  hideAndExpand_gpa() {

    var displayValue = $('#colSm5_gpa').css('display');
    if (displayValue === 'block') {
      $('#colSm5_gpa').slideUp('slow', function () {
        $('#colSm7_gpa').removeClass('col-sm-7').addClass('col-sm-12');
        $('#colSm5_gpa').css('display', 'none');
        $('#rightbtn_gpa').removeClass('rightbtn_gpa').addClass('fliprightbtn_gpa');
        $('.wrarrow_gpa').css('transform', 'rotateY(180deg)');
      });
    } else {
      $('#colSm5_gpa').slideUp('slow', function () {
        $('#colSm7_gpa').removeClass('col-sm-12').addClass('col-sm-7');
        $('#colSm5_gpa').css('display', 'block');
        $('#rightbtn_gpa').removeClass('fliprightbtn_gpa').addClass('rightbtn_gpa');
        $('.wrarrow_gpa').css('transform', 'rotateY(0deg)');
      });
    }

  }

  hideAndExpand_gtl() {

    var displayValue = $('#colSm5_gtl').css('display');
    if (displayValue === 'block') {
      $('#colSm5_gtl').slideUp('slow', function () {
        $('#colSm7_gtl').removeClass('col-sm-7').addClass('col-sm-12');
        $('#colSm5_gtl').css('display', 'none');
        $('#rightbtn_gtl').removeClass('rightbtn_gtl').addClass('fliprightbtn_gtl');
        $('.wrarrow_gtl').css('transform', 'rotateY(180deg)');
      });
    } else {
      $('#colSm5_gtl').slideUp('slow', function () {
        $('#colSm7_gtl').removeClass('col-sm-12').addClass('col-sm-7');
        $('#colSm5_gtl').css('display', 'block');
        $('#rightbtn_gtl').removeClass('fliprightbtn_gtl').addClass('rightbtn_gtl');
        $('.wrarrow_gtl').css('transform', 'rotateY(0deg)');
      });
    }

  }

  hideAndExpand_opd(e: any) {

    var displayValue = $('#colSm5_opd').css('display');
    if (displayValue === 'block') {
      $('#colSm5_opd').slideUp('slow', function () {
        $('#colSm7_opd').removeClass('col-sm-7').addClass('col-sm-12');
        $('#colSm5_opd').css('display', 'none');
        $('#rightbtn_opd').removeClass('rightbtn_opd').addClass('fliprightbtn_opd');
        $('.wrarrow_opd').css('transform', 'rotateY(180deg)');
      });
    } else {
      $('#colSm5_opd').slideUp('slow', function () {
        $('#colSm7_opd').removeClass('col-sm-12').addClass('col-sm-7');
        $('#colSm5_opd').css('display', 'block');
        $('#rightbtn_opd').removeClass('fliprightbtn_opd').addClass('rightbtn_opd');
        $('.wrarrow_opd').css('transform', 'rotateY(0deg)');
      });
    }

    e.stopPropagation();
    console.log("Button 1 clicked");

  }

  getemployeepolicies(){

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id')      
    };

    this.dataservice.getemployeepolicies(postdata, localStorage.getItem('Token'))
      .subscribe((result) => {                  

        for(var i = 0; i<result.data.dashboard_details.length; i++){
          if(result.data.dashboard_details[i]['name'] == 'Group Mediclaim'){
            // console.log(result.data.dashboard_details[i]['policy_features']);
            this.gmc_feature_arr = result.data.dashboard_details[i]['policy_features'];  
          }         
        }  

        localStorage.setItem('enrollment_handbook',result.data.enrollment_handbook);
        
        // console.log(this.gmc_feature_arr);

      }, (error) => {         
        
        if(error.error.message == 'Unauthenticated.'){
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }
    });
  }

  gotopage(id: any){

    this.route.navigate(['/insurance']);
    this.partner_id = id;

    localStorage.setItem('partner_id', this.partner_id);
  }

  // Function to convert keys
  
  convertKeys(data:any) {
    const dataArray:any = Object.values(data);

    // Now you can iterate over the array
    for (let i = 0; i < dataArray.length; i++) {
        // Check if the current element is a string before calling replace
        if (typeof dataArray[i] === 'string') {
            dataArray[i] = dataArray[i].replace(/_/g, ' '); // Replace underscores with spaces
        }
    }
    return dataArray;
  }

  assignBackgroundColors(tableArray:any) {
    const numRecords = tableArray.length;
    const tabColors = [];

    console.log(this.policyname_color);


    let codes:any;
    if(this.policyname_color == 'gmc_dropdown' || this.policyname_color == 'gmc_topup_dropdown'){
      codes = this.gmccolorCodes;
    }
    if(this.policyname_color == 'gpa_dropdown' || this.policyname_color == 'gpa_topup_dropdown'){
      codes = this.gpacolorCodes;
    }
    if(this.policyname_color == 'gtl_dropdown' || this.policyname_color == 'gtl_topup_dropdown'){
      codes = this.gtlcolorCodes;
    }

    if (numRecords === 1) {
        tabColors.push(codes[0]);
    } else if (numRecords === 2) {
        tabColors.push(codes[0], codes[5]);
    } else if (numRecords === 3) {
        tabColors.push(codes[0], codes[2], codes[5]);
    } else if (numRecords === 4) {
        tabColors.push(codes[0], codes[2], codes[3], codes[5]);
    } else if (numRecords >= 5) {
        for (let i = 0; i < numRecords; i++) {
            tabColors.push(codes[i]);
        }
    }

    return tabColors;
  }

  getColumns(): string[] {
    // Assuming all rows have the same set of columns
    return Object.keys(this.TableArray[0]).filter(key => key !== 'title');
  }

}

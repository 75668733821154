<!-- header -->
<app-web-menu-bar></app-web-menu-bar>
<!-- header-end -->

<!-- main-area -->
<main style="background-color: #fff !important;">
    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        
       
    <div class="main_title">
        <span>Vision & Mission</span>
    </div>

    <section class="vision-mission-section my-5">
        <div class="container vision-mission-container">
            <div class="row">                            
                <!-- Vision Card -->
                <div class="col-lg-6 col-md-12 mb-4">
                    <div class="card">
                        <div class="card-body">
                            <h2 class="card-title">Vision</h2>
                            <p>At NeoDigit, our vision is to transform the insurance industry with cutting-edge technology solutions, empowering businesses to excel in a rapidly evolving landscape.</p>
                            <p>We envision a future where:</p>
                            <ul>
                                <li><strong>Innovation and Expertise Converge:</strong> Delivering exceptional support through the latest advancements and deep industry knowledge.</li>
                                <li><strong>Timely Solutions Drive Growth:</strong> Providing effective solutions that propel business expansion.</li>
                                <li><strong>Proactive Monitoring Ensures Seamless Operations:</strong> Maintaining smooth operations through continuous oversight.</li>
                                <li><strong>Personalized Guidance Fosters Excellence:</strong> Offering tailored training and support to cultivate a culture of high performance.</li>
                                <li><strong>Scalable Solutions Adapt to Change:</strong> Ensuring our technology evolves with your needs.</li>
                            </ul>
                            <p>At NeoDigit, we're dedicated to shaping the future of insurance, one ground-breaking solution at a time. Join us in redefining the possibilities of Insurtech.</p>
                        </div>
                    </div>
                </div>

                <!-- Mission Card -->
                <div class="col-lg-6 col-md-12 mb-4">
                    <div class="card">
                        <div class="card-body">
                            <h2 class="card-title">Mission</h2>
                            <p>Regardless of size, every insurance intermediary or corporate agent should have a strong online presence.</p>
                            <p>With a well-established digital footprint, you can:</p>
                            <ul>
                                <li><strong>Operate 24/7:</strong> Generate business and connect with clients around the clock, every day of the year.</li>
                                <li><strong>Overcome Geographical Limits:</strong> Reach potential clients beyond your local area, expanding your market.</li>
                                <li><strong>Enhance Visibility:</strong> Make it easier for clients to find and engage with your services.</li>
                            </ul>
                            <p>Harness the power of technology & the internet to boost your business and stay competitive.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>    
    
</main>
<!-- main-area-end -->
 
<!-- footer -->
<app-web-footer></app-web-footer>
<!-- footer-end -->

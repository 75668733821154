<div id="stepper2" class="bs-stepper bg-white my-4 mb-5 rounded p-3 mainstepper_div">
    
    <div class="flagDiv">
        
        <span class="plan_step3">GPA</span>
        
        <div class="bs-stepper-header">
            <div class="step" data-target="#test-l-1" style="margin-left: 20px;">
                <button class="step-trigger">
                    <span class="bs-stepper-circle">1</span>
                    <span class="bs-stepper-label">Product</span>
                </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-l-2">
                <button class="step-trigger">
                    <span class="bs-stepper-circle">2</span>
                    <span class="bs-stepper-label">Policy Details</span>
                </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-l-3">
                <button class="step-trigger">
                    <span class="bs-stepper-circle">3</span>
                    <span class="bs-stepper-label">Policy Features</span>
                </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#test-l-4">
                <button class="step-trigger">
                    <span class="bs-stepper-circle">4</span>
                    <span class="bs-stepper-label">Document Upload</span>
                </button>
            </div>
        </div>
    </div>

    <div class="progressroot">
        <div [ngClass]="{'step1': stepcomplete === 'step1'}"></div>
        <div [ngClass]="{'step2': stepcomplete === 'step2'}"></div>
        <div [ngClass]="{'step3': stepcomplete === 'step3'}"></div>
        <div [ngClass]="{'step4': stepcomplete === 'step4'}"></div>
    </div>

    <div class="bs-stepper-content">

            <div id="test-l-1" class="content">                
                <form [formGroup]="policyDetails">
                    <h3 class="bs-title">Product Name </h3>
                    <div class="alert alert-success" id="validmsg" (click)="closealert()" style="display: none;">
                        <strong>Client created Successfully! {{ validmsg }} <span class="alertspan">&times;</span></strong>
                    </div>
                    <div class="alert alert-danger" id="errormsg" (click)="closealert()" style="display: none;">
                        <strong>Something went wrong, client not created. {{ validmsg }} <span class="alertspan">&times;</span></strong>
                    </div>
                    <!-- <p class="bs-p-normal">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                    <hr>
                    <div class="row">                                                                                             

                        <!--------Section 3------->
                        <div class="col-sm-4">
                            <h5 class="bs-left-form-label">Policy Details</h5>
                            <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                        </div>
                        <div class="col-sm-8 row">                            

                            <div class="form-group">
                                <div class="mb-2 bs-form-label">Benefit Structure <i class="bi bi-exclamation-circle" style="width:20px; height:20px; transform: rotate(-180deg)"></i></div>                            
                                <input type="radio" class="form-radio" value="manually" formControlName="upload_check" id="upload_check" (change)="entermanually();" /> <span class="bs-radio-label"> Enter Manually </span>                            
                                &nbsp;
                                <input type="radio" class="form-radio" value="policy_template" formControlName="upload_check" id="upload_check" (change)="entermanually();" /> <span class="bs-radio-label"> Upload Excel Template </span>                                                        
                                &nbsp;
                                <input type="radio" class="form-radio" value="existing_doc" formControlName="upload_check" id="upload_check" style="pointer-events:none;" /> <span class="bs-radio-label" style="color:#AAAAAA"> Upload Policy PDF </span>
                                <span style="font-size:12px ; float:right; color: #1489F3; cursor: pointer" (click)="downloadPolicyDetails()">Download Sample Template</span>                                                                        
                            </div>
                            

                            <div class="col-sm-6 form-group mt-3 existing_policy_check_yesno">
                                <label class="bs-form-label" for="insurance_company">Insurance company <span class="redstar">*</span></label>
                                <!-- <select class="form-select" formControlName="insurance_company" id="insurance_company" 
                                [ngClass]="{'control-red': policyDetailsSubmitted && getpolicyDetailsFormControls.insurance_company.errors}"
                                required >
                                    <option value="" selected>Select</option>
                                </select>                            
                                <ng-container
                                    *ngIf="policyDetailsSubmitted && getpolicyDetailsFormControls.insurance_company.errors">
                                    <p class="error">
                                    Insurance company is required
                                    </p>
                                </ng-container> -->

                                <ng-select formControlName="insurance_company" id="insurance_company" 
                                            [items]="isuranceCompanyDetailsarr" 
                                            bindLabel="name" 
                                            bindValue="insure_comp_id"
                                            [ngClass]="{'control-red': policyDetailsSubmitted && getpolicyDetailsFormControls.insurance_company.errors}"
                                            required>                                    
                                </ng-select>
                                 <ng-container
                                    *ngIf="policyDetailsSubmitted && getpolicyDetailsFormControls.insurance_company.errors">
                                    <p class="error">
                                    Insurance company is required
                                    </p>
                                </ng-container>
                                
                            </div>                                                   

                            <div class="col-sm-6 form-group mt-3 existing_policy_check_yesno">
                                <label class="bs-form-label" for="broker_name">Broker Name <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="broker_name" id="broker_name" style="line-height: 1.7;"
                                [ngClass]="{'control-red': policyDetailsSubmitted && getpolicyDetailsFormControls.broker_name.errors}"
                                required>
                                    <option value="" selected>Select</option>
                                </select>
                                <ng-container
                                    *ngIf="policyDetailsSubmitted && getpolicyDetailsFormControls.broker_name.errors">
                                    <p class="error">
                                    Broker Name is required
                                    </p>
                                </ng-container>
                            </div>                        
                                                
                            <div class="form-group mt-3" id="div_upload_policydocument" style="display: none">
                                <label class="bs-form-label" for="upload_policydocument">Upload policy document</label>
                                <label for="file" id="custom-file-upload_upload_policydocument" class="custom-file-upload form-control" style="text-align:center; cursor:pointer">
                                    <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_upload_policydocument" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>
                                </label>
                                <input id="file" type="file" (change)="upload_policydocument_change($event)" enctype="multipart/form-data" accept=".xlsx, .xls, .csv, application/pdf" style="display:none" />
                                <small style="font-size: 12px; color:#605D62" id="small_upload_policydocument">Upload only .xlxs, .xls files (Max size 4MB)</small>

                            </div>
                        </div>
                        <hr class="my-3">
                        <!--------END------->

                        <div>
                            <button (click)="next(1)" id="manual_flow_save" class="btn btn-primary" style="float: right;">Save & Continue</button>
                            <button (click)="next(1)" id="excel_flow_save" class="btn btn-primary" style="float: right;display:none;">Upload</button>
                        </div>
                    
                    </div>
                    
                </form>
            </div>

            <div id="test-l-2" class="content">
                <form [formGroup]="verifypolicyDetails">
                <h3 class="bs-title">Policy Details</h3>
                <!-- <p class="bs-p-normal">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                <hr>
                <div class="row">
                    <div class="col-sm-4">
                        <h5 class="bs-left-form-label">Policy Details</h5>
                        <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                    </div>
                    <div class="col-sm-8">
                        <div class="row">
                            <div class="col-sm-6 form-group policy_date step2hideFields" style="display:none">
                                <label class="bs-form-label" for="policy_date">Policy inception date <span class="redstar">*</span></label>
                                <input type="date" class="form-control" formControlName="policy_date" id="policy_date" (change)="loadecpirydate()"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_date.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_date.errors">
                                    <p class="error">
                                    Policy inception date is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group how_soon_buy">
                                <label class="bs-form-label" for="how_soon_buy">How soon you planning to buy <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="how_soon_buy" id="how_soon_buy" (change)="how_soon_buy_check($event)"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.how_soon_buy.errors}"
                                required>
                                    <option value="" selected>Select</option>
                                    <option value="Immediatly">Immediatly</option>
                                    <option value="Within a month">Within a month</option>
                                    <option value="Specific month">Specific month</option>
                                    <option value="Not yet decided">Not yet decided</option>
                                </select>                            
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.how_soon_buy.errors">
                                    <p class="error">
                                    This is required field
                                    </p>
                                </ng-container>
                            </div>                            
                            <div class="col-sm-6 form-group step2hideFields">
                                <label class="bs-form-label" for="policy_expirydate">Policy expiry date <span class="redstar">*</span></label>
                                <input type="date" class="form-control" formControlName="policy_expirydate" id="policy_expirydate"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_expirydate.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_expirydate.errors">
                                    <p class="error">
                                    Policy expiry date is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group mt-3 data_report_date step2hideFields" style="display:none">
                                <label class="bs-form-label" for="data_report_date">Data report date <span class="redstar">*</span></label>
                                <input type="date" class="form-control" formControlName="data_report_date" id="data_report_date" min="{{min_date}}" max="{{max_date}}"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.data_report_date.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.data_report_date.errors">
                                    <p class="error">
                                    Data report date is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group choose_month" style="display:none">
                                <label class="bs-form-label" for="choose_month">Choose month <span class="redstar">*</span></label>
                                <select class="form-select" formControlName="choose_month" id="choose_month"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.choose_month.errors}"
                                required>
                                    <option value="0" selected>Select</option>
                                    <option value="January">January</option>
                                    <option value="February">February</option>
                                    <option value="March">March</option>
                                    <option value="April">April</option>
                                    <option value="May">May</option>
                                    <option value="June">June</option>
                                    <option value="July">July</option>
                                    <option value="August">August</option>
                                    <option value="September">September</option>
                                    <option value="October">October</option>
                                    <option value="November">November</option>
                                    <option value="December">December</option>
                                </select>
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.choose_month.errors">
                                    <p class="error">
                                    Policy expiry date is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group mt-3 step2hideFields">
                                <label class="bs-form-label" for="policy_number">Policy number <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="policy_number" id="policy_number" placeholder="340100502022000275"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_number.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.policy_number.errors">
                                    <p class="error">
                                    Policy number is required
                                    </p>
                                </ng-container>
                            </div>                                                        
                        </div>
                    </div>

                    <div class="row p-0 m-0">
                        <div class="col-sm-4">
                            <div class="p-5 px-0 pb-0 step2hideFields">
                                <label class="bs-form-label" style="color:#000">Lives at Inception</label>
                            </div>
                            <div class="p-5 px-0 pt-3 step2hideFields">
                                <label class="bs-form-label" style="position: relative; top: 30px;color:#000">Lives at Renewal</label>
                            </div>
                        </div>
                        <div class="row col-sm-8 p-0 m-0">
                            <div class="col-sm-6 form-group emp_atstart" style="margin-top: 16px !important;"> 
                                <label class="bs-form-label" for="emp_atstart">No of employees <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="emp_atstart" id="emp_atstart" (keypress)="isNumber($event)" (input)="isformat($event)" placeholder="Enter no of employee"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.emp_atstart.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.emp_atstart.errors">
                                    <p class="error">
                                    No of employees at start of the policy is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group lives_covered mt-3 step2hideFields" style="display:none">
                                <label class="bs-form-label" for="lives_covered">No of total lives <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="lives_covered" id="lives_covered" (blur)="lives_covered($event)" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter no of lives"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.lives_covered.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.lives_covered.errors">
                                    <p class="error">
                                    No of lives covered at start of the policy is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group step2hideFields step2hideFields">
                                <label class="bs-form-label" for="emp_atreport">No of employees <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="emp_atreport" id="emp_atreport" (keypress)="isNumber($event)" (input)="isformat($event)" placeholder="Enter no of employee"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.emp_atreport.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.emp_atreport.errors">
                                    <p class="error">
                                    No of employees as on report date is required
                                    </p>
                                </ng-container>
                            </div>
                            <div class="col-sm-6 form-group step2hideFields step2hideFields">
                                <label class="bs-form-label" for="lives_atreport">No of total lives <span class="redstar">*</span></label>
                                <input type="text" class="form-control" formControlName="lives_atreport" id="lives_atreport" (blur)="lives_covered($event)" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter no of lives"
                                [ngClass]="{'control-red': verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.lives_atreport.errors}"
                                required />
                                <ng-container
                                    *ngIf="verifypolicyDetailsSubmitted && getverifypolicyDetailsFormControls.lives_atreport.errors">
                                    <p class="error">
                                    No of lives covered as on report date is required
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <hr class="my-3">
                    <div>
                        <button (click)="next(2)" class="btn btn-primary" style="float: right;">Save & Continue</button>
                        <button (click)="previous()" class="btn btn-primary m-3 mt-0" style="float: right;
    background: #fff;
    border: solid 1px #4E0099;
    color: #4E0099;
    background: none !important;">Previous</button>
                    </div>
                </div>                

                </form>
            </div>

            <div id="test-l-3" class="content">

                <!--------- Banner ---------->
                <div class="row">
                    <div class="col-sm-6">
                        <img src="../../assets/img/policy/policybanner.png" class="img-fluid" alt="Img" style="display:block; margin:auto; width:300px" />
                    </div>
                    <div class="col-sm-6 textm">
                        <h3 class="bs-title alignc">Policy Features</h3>
                        <p class="bs-p-normal alignc"> 
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>
                            Nam non metus id augue tempor volutpat sit amet sit amet nunc.
                        </p>
                    </div>
                </div>
                <hr class="my-4">
                <!--------- END ------------>

                <form [formGroup]="policyFeatures">                              

                <!---- Sum Insured Coverage Accordian ---->
                <h5 class="bs-left-form-label accordheading SIheading" (click)="sumisuredaccord()">Sum Insured Coverage
                    <img id="sumisured_down_arrow" src="../../assets/img/common icons/arrow-down.png" class="arrowup" />
                    <img id="sumisured_up_arrow" src="../../assets/img/common icons/arrow-up.png" class="arrowdown" />
                    <img src="../../assets/img/policy/sum-insured-coverage.png" class="headicon" />
                    <img id="SItick" src="../../assets/img/policy/tickicon.png" class="tickicon" alt="Icon"/>
                    <img id="SItick_error" src="../../assets/img/policy/errorpolicy.png" class="tickicon" alt="Icon" />
                    <div class="circular-progress" id="ProgSI">
                        <div class="value-container" id="ValueSI">0%</div>
                    </div>
                    <p class="bs-p-light headspan">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                </h5>
                <div id="sumisured_accord" class="accord_div">

                    <div class="row">                    

                        <div class="col-sm-12">
                            <div class="row">                                                                

                                <label class="bs-form-label">What is the basis of Accidental sum assured?</label>

                                <div class="form-check col-sm-4 mt-3">
                                    <input type="radio" class="form-radio-input parentcheck" value="Multiple of Salary" formControlName="SI_type" name="SI_type" (click)="show_SI_div('Multiple of Salary')" id="SI_type" style="background: #fff" />
                                    <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Multiple of Salary</label>
                                </div>                                                                                        
                                <div class="form-check col-sm-4 mt-3">
                                    <input type="radio" class="form-radio-input parentcheck" value="Flat" formControlName="SI_type" name="SI_type" (click)="show_SI_div('Flat')" id="SI_type" style="background: #fff" />
                                    <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Flat</label>
                                </div>
                                <div class="form-check col-sm-4 mt-3">
                                    <input type="radio" class="form-radio-input parentcheck" value="Graded" formControlName="SI_type" name="SI_type" (click)="show_SI_div('Graded')" id="SI_type" style="background: #fff" />
                                    <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Graded</label>
                                </div>                                

                                <div id="div_Multiple_of_Salary" style="display:none">
                                    
                                    <div class="col-sm-4 form-group mt-3">
                                        <label class="bs-form-label" for="multiple_of_salary">Define the multiple of salary? <span class="redstar">*</span></label>
                                        <select class="form-select" formControlName="multiple_of_salary" id="multiple_of_salary" (change)="checkMOS($event)">
                                            <option value="" selected>Select</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                        </select>                            
                                        <p class="error" id="multiple_of_salary_error" style="display:none">
                                            This field is required
                                        </p>
                                    </div>

                                </div>

                                <div id="div_Flat" style="display:none">
                                    
                                    <div class="col-sm-4 form-group mt-3">
                                        <label class="bs-form-label" for="Define_flat_SI">Define flat sum insured under accidental plan <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Define_flat_SI" id="Define_flat_SI" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                        <p class="error" id="Define_flat_SI_error" style="display:none">
                                            This field is required
                                        </p>
                                    </div>

                                </div>

                                <div id="div_Graded" style="display:none">

                                    <div class="col-sm-6 form-group mt-3">
                                        <label class="bs-form-label" for="SI_Junior_management">Define graded accidental sum insured- Junior management <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="SI_Junior_management" id="SI_Junior_management" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                        <p class="error" id="SI_Junior_management_error" style="display:none">
                                            This field is required
                                        </p>
                                    </div>

                                    <div class="col-sm-6 form-group mt-3">
                                        <label class="bs-form-label" for="SI_Middle_management">Define graded accidental sum insured- Middle management <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="SI_Middle_management" id="SI_Middle_management" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                        <p class="error" id="SI_Middle_management_error" style="display:none">
                                            This field is required
                                        </p>
                                    </div>

                                    <div class="col-sm-6 form-group mt-3">
                                        <label class="bs-form-label" for="SI_Senior_management">Define graded accidental sum insured- Senior management <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="SI_Senior_management" id="SI_Senior_management" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                        <p class="error" id="SI_Senior_management_error" style="display:none">
                                            This field is required
                                        </p>
                                    </div>

                                    <div class="col-sm-12">
                                        <div class="row newgradedField">
    
                                        </div>
                                    </div>
                                    <div class="col-sm-12 mt-3">
                                        <label class="bs-form-label" (click)="addGradedField()" style="cursor: pointer">
                                            <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                            <span style="color: #1489F3;">Add Amount</span>
                                        </label>
                                    </div>                         

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!----------------------------->                               

                <!---- OPD benefits Accordian ---->
                <h5 class="bs-left-form-label accordheading OPDheading" (click)="OPDaccord()">Death and Disability Benefit
                    <img id="OPD_down_arrow" src="../../assets/img/common icons/arrow-down.png" class="arrowup" />
                    <img id="OPD_up_arrow" src="../../assets/img/common icons/arrow-up.png" class="arrowdown" />
                    <img src="../../assets/img/policy/opd.png" class="headicon" />
                    <img id="OPDtick" src="../../assets/img/policy/tickicon.png" class="tickicon" alt="Icon"/>
                    <img id="OPDtick_error" src="../../assets/img/policy/errorpolicy.png" class="tickicon" alt="Icon" />
                    <div class="circular-progress" id="ProgOPD">
                        <div class="value-container" id="ValueOPD">0%</div>
                    </div>
                    <p class="bs-p-light headspan">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                </h5>
                <div id="OPD_accord" class="accord_div">

                    <div class="row">                       

                        <div class="col-sm-12">
                            <div class="row">
                                
                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Accidental_Death">Accidental Death <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Accidental_Death_div')" (mouseleave)="mouseLeave($event, 'Accidental_Death_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Accidental_Death_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Accidental Death</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Accidental_Death" formControlName="Accidental_Death" name="Accidental_Death" value="No" (change)="green_red_change('Accidental_Death', 'no')" />
                                            <label for="radio-two_Accidental_Death" id="Accidental_Death_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Accidental_Death" formControlName="Accidental_Death" name="Accidental_Death" value="Yes" (change)="green_red_change('Accidental_Death', 'yes')" />
                                            <label for="radio-one_Accidental_Death" id="Accidental_Death_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <!-- <div class="col-sm-4 form-group" id="divAccidental_Death_limit" style="display: none">
                                        <label class="bs-form-label" for="Accidental_Death_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Accidental_Death_limit" id="Accidental_Death_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Accidental_Death_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div> -->
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Permanent_Total_Disability">Permanent Total Disability <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Permanent_Total_Disability_div')" (mouseleave)="mouseLeave($event, 'Permanent_Total_Disability_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Permanent_Total_Disability_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Permanent Total Disability</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Permanent_Total_Disability" formControlName="Permanent_Total_Disability" name="Permanent_Total_Disability" value="No" (change)="checkradio('divPermanent_Total_Disability_limit', 'NotCovered'); green_red_change('Permanent_Total_Disability', 'no')" />
                                            <label for="radio-two_Permanent_Total_Disability" id="Permanent_Total_Disability_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Permanent_Total_Disability" formControlName="Permanent_Total_Disability" name="Permanent_Total_Disability" value="Yes" (change)="checkradio('divPermanent_Total_Disability_limit', 'Covered'); green_red_change('Permanent_Total_Disability', 'yes')" />
                                            <label for="radio-one_Permanent_Total_Disability" id="Permanent_Total_Disability_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divPermanent_Total_Disability_limit" style="display: none">
                                        <label class="bs-form-label" for="Permanent_Total_Disability_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <!-- <input type="text" class="form-control" formControlName="Permanent_Total_Disability_limit" id="Permanent_Total_Disability_limit" (keypress)="isNumber($event)" (input)="isformat($event)" /> -->
                                        <select class="form-select" formControlName="Permanent_Total_Disability_limit" id="Permanent_Total_Disability_limit">
                                            <option value="" selected>Select</option>
                                            <option>100%</option>
                                            <option>150%</option>
                                            <option>200%</option>
                                        </select>
                                        <p class="error" id="Permanent_Total_Disability_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>                                

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Permanent_Partial_Disability">Permanent Partial Disability <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Permanent_Partial_Disability_div')" (mouseleave)="mouseLeave($event, 'Permanent_Partial_Disability_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Permanent_Partial_Disability_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Permanent Partial Disability</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Permanent_Partial_Disability" formControlName="Permanent_Partial_Disability" name="Permanent_Partial_Disability" value="No" (change)="checkradio('divPermanent_Partial_Disability_limit', 'NotCovered'); green_red_change('Permanent_Partial_Disability', 'no')" />
                                            <label for="radio-two_Permanent_Partial_Disability" id="Permanent_Partial_Disability_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Permanent_Partial_Disability" formControlName="Permanent_Partial_Disability" name="Permanent_Partial_Disability" value="Yes" (change)="checkradio('divPermanent_Partial_Disability_limit', 'Covered'); green_red_change('Permanent_Partial_Disability', 'yes')" />
                                            <label for="radio-one_Permanent_Partial_Disability" id="Permanent_Partial_Disability_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divPermanent_Partial_Disability_limit" style="display: none">
                                        <!-- <label class="bs-form-label" for="Permanent_Partial_Disability_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Permanent_Partial_Disability_limit" id="Permanent_Partial_Disability_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <input type="file" id="Permanent_Partial_Disability_limit" formControlName="Permanent_Partial_Disability_limit" accept=".png, .jpg, .jpeg" enctype="multipart/form-data" />
                                        <p class="error" id="Permanent_Partial_Disability_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p> -->

                                        <div class="form-group">
                                            <label class="bs-form-label" for="img_document">Upload Image</label>
                                            <label for="Permanent_Partial_Disability_limit" id="custom-file-upload_img_document" class="custom-file-upload form-control" style="text-align:center; cursor:pointer">
                                                <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_img_document" style="color:#4E0099; font-weight: 600; text-align:center">Upload Image</span>
                                            </label>
                                            <input id="Permanent_Partial_Disability_limit" type="file" (change)="img_document_change($event)" accept=".png, .jpg, .jpeg" enctype="multipart/form-data" style="display: none" />
                                        
                                        </div>
                                        <small style="font-size: 12px; color:#605D62" id="small_img_document">Upload only .png, .jpg, .jpeg files (Max size 4MB)</small>

                                    </div>
                                </div>                                

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-6 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Total_disability_loss_of_wages">Temporary Total Disability (Loss Of Wages) <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Total_disability_loss_of_wages_div')" (mouseleave)="mouseLeave($event, 'Total_disability_loss_of_wages_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Total_disability_loss_of_wages_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Temporary Total Disability (Loss Of Wages)</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Total_disability_loss_of_wages" formControlName="Total_disability_loss_of_wages" name="Total_disability_loss_of_wages" value="No" (change)="checkradio('divTotal_disability_loss_of_wages_limit', 'NotCovered'); green_red_change('Total_disability_loss_of_wages', 'no')" />
                                            <label for="radio-two_Total_disability_loss_of_wages" id="Total_disability_loss_of_wages_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Total_disability_loss_of_wages" formControlName="Total_disability_loss_of_wages" name="Total_disability_loss_of_wages" value="Yes" (change)="checkradio('divTotal_disability_loss_of_wages_limit', 'Covered'); green_red_change('Total_disability_loss_of_wages', 'yes')" />
                                            <label for="radio-one_Total_disability_loss_of_wages" id="Total_disability_loss_of_wages_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-12 form-group" id="divTotal_disability_loss_of_wages_limit" style="display: none">
                                        <!-- <label class="bs-form-label" for="Total_disability_loss_of_wages_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Total_disability_loss_of_wages_limit" id="Total_disability_loss_of_wages_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Total_disability_loss_of_wages_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p> -->

                                        <div class="row">
                                            <div class="col-sm-3">
                                                
                                                <div style="display: flex; margin-top: 20px;">
                                                    <input type="text" style="width: 30%;height: 30px" class="form-control" formControlName="Total_disability_loss_of_wages_limit" id="Total_disability_loss_of_wages_limit" (keypress)="isNumber($event)" (input)="age_limit($event, 'agerange')" />
                                                    <p class="error" id="Total_disability_loss_of_wages_limit_error" style="display:none; margin-bottom: -10px;">
                                                        This field is required 
                                                    </p>
                                                    <span style="font-size: 12px; margin-top:5px; margin-left: 10px">% of Sum Insured OR ₹</span>
                                                </div>
                                                
                                            </div>
                                            <div class="col-sm-6">

                                                <div style="display: flex; margin-left: 12px;margin-top: 20px;">
                                                    <input type="text" style="width: 30%;height: 30px" class="form-control" formControlName="Total_disability_loss_of_wages_INR_per_week" id="Total_disability_loss_of_wages_INR_per_week" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                                    <p class="error" id="Total_disability_loss_of_wages_INR_per_week_error" style="display:none; margin-bottom: -10px;">
                                                        This field is required 
                                                    </p>
                                                    <span style="font-size: 12px; margin-top:5px; margin-left: 10px">Per Week OR Actual Weekly Salary which ever is lower for</span>
                                                </div>
                                                                                                
                                            </div>
                                            <div class="col-sm-3">

                                                <div style="display: flex; margin-left: 12px;margin-top: 20px;">
                                                    <select class="form-select" style="width: 40%;height: 30px" formControlName="Total_disability_loss_of_wages_actual_weekly_salary" id="Total_disability_loss_of_wages_actual_weekly_salary">
                                                        <option value="" selected>Select</option>
                                                        <option value="52">52</option>
                                                        <option value="100">100</option>
                                                        <option value="104">104</option>
                                                    </select>                            
                                                    <p class="error" id="Total_disability_loss_of_wages_actual_weekly_salary_error" style="display:none">
                                                        This field is required
                                                    </p>
                                                    <span style="font-size: 12px; margin-top:5px; margin-left: 10px">Weeks</span>
                                                </div>
                                                
                                            </div>                                                                                                                                     
                                        </div>

                                    </div>
                                </div>								                                

                            </div>
                        </div>

                    </div>
                </div>
                <!----------------------------->

                <!---- Additional Benefits Accordian ---->
                <h5 class="bs-left-form-label accordheading additionalbenefitsheading" (click)="Othersaccord()">Additional Benefits
                    <img id="Others_down_arrow" src="../../assets/img/common icons/arrow-down.png" class="arrowup" />
                    <img id="Others_up_arrow" src="../../assets/img/common icons/arrow-up.png" class="arrowdown" />
                    <img src="../../assets/img/policy/benefit.png" class="headicon" />
                    <img id="additionalbenefitstick" src="../../assets/img/policy/tickicon.png" class="tickicon" alt="Icon"/>
                    <img id="additionalbenefitstick_error" src="../../assets/img/policy/errorpolicy.png" class="tickicon" alt="Icon" />
                    <div class="circular-progress" id="ProgOthers">
                        <div class="value-container" id="ValueOthers">0%</div>
                    </div>
                    <p class="bs-p-light headspan">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                </h5>
                <div id="Others_accord" class="accord_div">

                    <div class="row">                        

                        <div class="col-sm-12">
                            <div class="row">

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Double_dismemberment">Double Dismemberment (S.I to double in case of  dismemberment) <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Double_dismemberment_div')" (mouseleave)="mouseLeave($event, 'Double_dismemberment_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Double_dismemberment_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Double Dismemberment (S.I to double in case of  dismemberment)</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Double_dismemberment" formControlName="Double_dismemberment" name="Double_dismemberment" value="No" (change)="checkradio('divDouble_dismemberment_limit', 'NotCovered'); green_red_change('Double_dismemberment', 'no')" />
                                            <label for="radio-two_Double_dismemberment" id="Double_dismemberment_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Double_dismemberment" formControlName="Double_dismemberment" name="Double_dismemberment" value="Yes" (change)="checkradio('divDouble_dismemberment_limit', 'Covered'); green_red_change('Double_dismemberment', 'yes')" />
                                            <label for="radio-one_Double_dismemberment" id="Double_dismemberment_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <!-- <div class="col-sm-4 form-group" id="divDouble_dismemberment_limit" style="display: none">
                                        <label class="bs-form-label" for="Double_dismemberment_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Double_dismemberment_limit" id="Double_dismemberment_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Double_dismemberment_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div> -->
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Medical_benefits_Medex">Medical Benefits  / Medex <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Medical_benefits_Medex_div')" (mouseleave)="mouseLeave($event, 'Medical_benefits_Medex_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Medical_benefits_Medex_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Medical Benefits  / Medex</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Medical_benefits_Medex" formControlName="Medical_benefits_Medex" name="Medical_benefits_Medex" value="No" (change)="checkradio('divMedical_benefits_Medex_limit', 'NotCovered'); green_red_change('Medical_benefits_Medex', 'no')" />
                                            <label for="radio-two_Medical_benefits_Medex" id="Medical_benefits_Medex_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Medical_benefits_Medex" formControlName="Medical_benefits_Medex" name="Medical_benefits_Medex" value="Yes" (change)="checkradio('divMedical_benefits_Medex_limit', 'Covered'); green_red_change('Medical_benefits_Medex', 'yes')" />
                                            <label for="radio-one_Medical_benefits_Medex" id="Medical_benefits_Medex_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divMedical_benefits_Medex_limit" style="display: none">
                                        <label class="bs-form-label" for="Medical_benefits_Medex_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Medical_benefits_Medex_limit" id="Medical_benefits_Medex_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Medical_benefits_Medex_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>								                                                               

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Transportation_of_mortal_remains">Transportation Of Mortal Remains <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Transportation_of_mortal_remains_div')" (mouseleave)="mouseLeave($event, 'Transportation_of_mortal_remains_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Transportation_of_mortal_remains_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Transportation Of Mortal Remains</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Transportation_of_mortal_remains" formControlName="Transportation_of_mortal_remains" name="Transportation_of_mortal_remains" value="No" (change)="checkradio('divTransportation_of_mortal_remains_limit', 'NotCovered'); green_red_change('Transportation_of_mortal_remains', 'no')" />
                                            <label for="radio-two_Transportation_of_mortal_remains" id="Transportation_of_mortal_remains_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Transportation_of_mortal_remains" formControlName="Transportation_of_mortal_remains" name="Transportation_of_mortal_remains" value="Yes" (change)="checkradio('divTransportation_of_mortal_remains_limit', 'Covered'); green_red_change('Transportation_of_mortal_remains', 'yes')" />
                                            <label for="radio-one_Transportation_of_mortal_remains"id=Transportation_of_mortal_remains_yes class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divTransportation_of_mortal_remains_limit" style="display: none">
                                        <label class="bs-form-label" for="Transportation_of_mortal_remains_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Transportation_of_mortal_remains_limit" id="Transportation_of_mortal_remains_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Transportation_of_mortal_remains_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Ambulance_charges">Ambulance Charges <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Ambulance_charges_div')" (mouseleave)="mouseLeave($event, 'Ambulance_charges_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Ambulance_charges_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Ambulance Charges</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Ambulance_charges" formControlName="Ambulance_charges" name="Ambulance_charges" value="No" (change)="checkradio('divAmbulance_charges_limit', 'NotCovered'); green_red_change('Ambulance_charges', 'no')" />
                                            <label for="radio-two_Ambulance_charges" id="Ambulance_charges_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Ambulance_charges" formControlName="Ambulance_charges" name="Ambulance_charges" value="Yes" (change)="checkradio('divAmbulance_charges_limit', 'Covered'); green_red_change('Ambulance_charges', 'yes')" />
                                            <label for="radio-one_Ambulance_charges" id="Ambulance_charges_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divAmbulance_charges_limit" style="display: none">
                                        <label class="bs-form-label" for="Ambulance_charges_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Ambulance_charges_limit" id="Ambulance_charges_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Ambulance_charges_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Modification_benefit">Modification Benefit <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Modification_benefit_div')" (mouseleave)="mouseLeave($event, 'Modification_benefit_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Modification_benefit_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Modification Benefit</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Modification_benefit" formControlName="Modification_benefit" name="Modification_benefit" value="No" (change)="checkradio('divModification_benefit_limit', 'NotCovered'); green_red_change('Modification_benefit', 'no')" />
                                            <label for="radio-two_Modification_benefit" id="Modification_benefit_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Modification_benefit" formControlName="Modification_benefit" name="Modification_benefit" value="Yes" (change)="checkradio('divModification_benefit_limit', 'Covered'); green_red_change('Modification_benefit', 'yes')" />
                                            <label for="radio-one_Modification_benefit" id="Modification_benefit_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divModification_benefit_limit" style="display: none">
                                        <label class="bs-form-label" for="Modification_benefit_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Modification_benefit_limit" id="Modification_benefit_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Modification_benefit_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Education_benefit">Education Benefit <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Education_benefit_div')" (mouseleave)="mouseLeave($event, 'Education_benefit_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Education_benefit_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Education Benefit</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Education_benefit" formControlName="Education_benefit" name="Education_benefit" value="No" (change)="checkradio('divEducation_benefit_limit', 'NotCovered'); green_red_change('Education_benefit', 'no')" />
                                            <label for="radio-two_Education_benefit" id="Education_benefit_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Education_benefit" formControlName="Education_benefit" name="Education_benefit" value="Yes" (change)="checkradio('divEducation_benefit_limit', 'Covered'); green_red_change('Education_benefit', 'yes')" />
                                            <label for="radio-one_Education_benefit" id="Education_benefit_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divEducation_benefit_limit" style="display: none">
                                        <label class="bs-form-label" for="Education_benefit_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Education_benefit_limit" id="Education_benefit_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Education_benefit_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Funeral_and_Repatriation_expenses">Funeral & Repatriation Expenses <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Funeral_and_Repatriation_expenses_div')" (mouseleave)="mouseLeave($event, 'Funeral_and_Repatriation_expenses_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Funeral_and_Repatriation_expenses_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Funeral & Repatriation Expenses</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Funeral_and_Repatriation_expenses" formControlName="Funeral_and_Repatriation_expenses" name="Funeral_and_Repatriation_expenses" value="No" (change)="checkradio('divFuneral_and_Repatriation_expenses_limit', 'NotCovered'); green_red_change('Funeral_and_Repatriation_expenses', 'no')" />
                                            <label for="radio-two_Funeral_and_Repatriation_expenses" id="Funeral_and_Repatriation_expenses_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Funeral_and_Repatriation_expenses" formControlName="Funeral_and_Repatriation_expenses" name="Funeral_and_Repatriation_expenses" value="Yes" (change)="checkradio('divFuneral_and_Repatriation_expenses_limit', 'Covered'); green_red_change('Funeral_and_Repatriation_expenses', 'yes')" />
                                            <label for="radio-one_Funeral_and_Repatriation_expenses" id="Funeral_and_Repatriation_expenses_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divFuneral_and_Repatriation_expenses_limit" style="display: none">
                                        <label class="bs-form-label" for="Funeral_and_Repatriation_expenses_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Funeral_and_Repatriation_expenses_limit" id="Funeral_and_Repatriation_expenses_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Funeral_and_Repatriation_expenses_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Carriage_of_dead_body">Carriage Of Dead Body <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Carriage_of_dead_body_div')" (mouseleave)="mouseLeave($event, 'Carriage_of_dead_body_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Carriage_of_dead_body_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Carriage Of Dead Body</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Carriage_of_dead_body" formControlName="Carriage_of_dead_body" name="Carriage_of_dead_body" value="No" (change)="checkradio('divCarriage_of_dead_body_limit', 'NotCovered'); green_red_change('Carriage_of_dead_body', 'no')" />
                                            <label for="radio-two_Carriage_of_dead_body" id="Carriage_of_dead_body_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Carriage_of_dead_body" formControlName="Carriage_of_dead_body" name="Carriage_of_dead_body" value="Yes" (change)="checkradio('divCarriage_of_dead_body_limit', 'Covered'); green_red_change('Carriage_of_dead_body', 'yes')" />
                                            <label for="radio-one_Carriage_of_dead_body" id="Carriage_of_dead_body_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divCarriage_of_dead_body_limit" style="display: none">
                                        <label class="bs-form-label" for="Carriage_of_dead_body_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Carriage_of_dead_body_limit" id="Carriage_of_dead_body_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Carriage_of_dead_body_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Emergency_family_travel">Emergency Family Travel <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Emergency_family_travel_div')" (mouseleave)="mouseLeave($event, 'Emergency_family_travel_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Emergency_family_travel_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Emergency Family Travel</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Emergency_family_travel" formControlName="Emergency_family_travel" name="Emergency_family_travel" value="No" (change)="checkradio('divEmergency_family_travel_limit', 'NotCovered'); green_red_change('Emergency_family_travel', 'no')" />
                                            <label for="radio-two_Emergency_family_travel" id="Emergency_family_travel_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Emergency_family_travel" formControlName="Emergency_family_travel" name="Emergency_family_travel" value="Yes" (change)="checkradio('divEmergency_family_travel_limit', 'Covered'); green_red_change('Emergency_family_travel', 'yes')" />
                                            <label for="radio-one_Emergency_family_travel" id="Emergency_family_travel_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divEmergency_family_travel_limit" style="display: none">
                                        <label class="bs-form-label" for="Emergency_family_travel_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Emergency_family_travel_limit" id="Emergency_family_travel_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Emergency_family_travel_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Animal_bites">Animal Bites <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Animal_bites_div')" (mouseleave)="mouseLeave($event, 'Animal_bites_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Animal_bites_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Animal Bites</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Animal_bites" formControlName="Animal_bites" name="Animal_bites" value="No" (change)="checkradio('divAnimal_bites_limit', 'NotCovered'); green_red_change('Animal_bites', 'no')" />
                                            <label for="radio-two_Animal_bites" id="Animal_bites_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Animal_bites" formControlName="Animal_bites" name="Animal_bites" value="Yes" (change)="checkradio('divAnimal_bites_limit', 'Covered'); green_red_change('Animal_bites', 'yes')" />
                                            <label for="radio-one_Animal_bites" id="Animal_bites_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divAnimal_bites_limit" style="display: none">
                                        <label class="bs-form-label" for="Animal_bites_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Animal_bites_limit" id="Animal_bites_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Animal_bites_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Assualt_cover">Assault Cover <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Assualt_cover_div')" (mouseleave)="mouseLeave($event, 'Assualt_cover_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Assualt_cover_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Assault Cover</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Assualt_cover" formControlName="Assualt_cover" name="Assualt_cover" value="No" (change)="checkradio('divAssualt_cover_limit', 'NotCovered'); green_red_change('Assualt_cover', 'no')" />
                                            <label for="radio-two_Assualt_cover" id="Assualt_cover_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Assualt_cover" formControlName="Assualt_cover" name="Assualt_cover" value="Yes" (change)="checkradio('divAssualt_cover_limit', 'Covered'); green_red_change('Assualt_cover', 'yes')" />
                                            <label for="radio-one_Assualt_cover" id="Assualt_cover_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divAssualt_cover_limit" style="display: none">
                                        <label class="bs-form-label" for="Assualt_cover_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Assualt_cover_limit" id="Assualt_cover_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Assualt_cover_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Adventure_sports">Adventure Sports <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Adventure_sports_div')" (mouseleave)="mouseLeave($event, 'Adventure_sports_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Adventure_sports_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Adventure Sports</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Adventure_sports" formControlName="Adventure_sports" name="Adventure_sports" value="No" (change)="checkradio('divAdventure_sports_limit', 'NotCovered'); green_red_change('Adventure_sports', 'no')" />
                                            <label for="radio-two_Adventure_sports" id="Adventure_sports_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Adventure_sports" formControlName="Adventure_sports" name="Adventure_sports" value="Yes" (change)="checkradio('divAdventure_sports_limit', 'Covered'); green_red_change('Adventure_sports', 'yes')" />
                                            <label for="radio-one_Adventure_sports" id="Adventure_sports_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divAdventure_sports_limit" style="display: none">
                                        <label class="bs-form-label" for="Adventure_sports_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Adventure_sports_limit" id="Adventure_sports_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Adventure_sports_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Account_payment_clause">Account Payment Clause <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Account_payment_clause_div')" (mouseleave)="mouseLeave($event, 'Account_payment_clause_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Account_payment_clause_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Account Payment Clause</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Account_payment_clause" formControlName="Account_payment_clause" name="Account_payment_clause" value="No" (change)="checkradio('divAccount_payment_clause_limit', 'NotCovered'); green_red_change('Account_payment_clause', 'no')" />
                                            <label for="radio-two_Account_payment_clause" id="Account_payment_clause_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Account_payment_clause" formControlName="Account_payment_clause" name="Account_payment_clause" value="Yes" (change)="checkradio('divAccount_payment_clause_limit', 'Covered'); green_red_change('Account_payment_clause', 'yes')" />
                                            <label for="radio-one_Account_payment_clause" id="Account_payment_clause_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divAccount_payment_clause_limit" style="display: none">
                                        <label class="bs-form-label" for="Account_payment_clause_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Account_payment_clause_limit" id="Account_payment_clause_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Account_payment_clause_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Broken_bone_coverage">Broken Bone Coverage <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Broken_bone_coverage_div')" (mouseleave)="mouseLeave($event, 'Broken_bone_coverage_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Broken_bone_coverage_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Broken Bone Coverage</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Broken_bone_coverage" formControlName="Broken_bone_coverage" name="Broken_bone_coverage" value="No" (change)="checkradio('divBroken_bone_coverage_limit', 'NotCovered'); green_red_change('Broken_bone_coverage', 'no')" />
                                            <label for="radio-two_Broken_bone_coverage" id="Broken_bone_coverage_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Broken_bone_coverage" formControlName="Broken_bone_coverage" name="Broken_bone_coverage" value="Yes" (change)="checkradio('divBroken_bone_coverage_limit', 'Covered'); green_red_change('Broken_bone_coverage', 'yes')" />
                                            <label for="radio-one_Broken_bone_coverage" id="Broken_bone_coverage_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divBroken_bone_coverage_limit" style="display: none">
                                        <label class="bs-form-label" for="Broken_bone_coverage_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Broken_bone_coverage_limit" id="Broken_bone_coverage_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Broken_bone_coverage_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <!-- <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Alcohol_benefit">ALCOHOL BENEFIT <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Alcohol_benefit_div')" (mouseleave)="mouseLeave($event, 'Alcohol_benefit_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Alcohol_benefit_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">ALCOHOL BENEFIT</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Alcohol_benefit" formControlName="Alcohol_benefit" name="Alcohol_benefit" value="No" (change)="checkradio('divAlcohol_benefit_limit', 'NotCovered')" />
                                            <label for="radio-two_Alcohol_benefit" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Alcohol_benefit" formControlName="Alcohol_benefit" name="Alcohol_benefit" value="Yes" (change)="checkradio('divAlcohol_benefit_limit', 'Covered')" />
                                            <label for="radio-one_Alcohol_benefit" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divAlcohol_benefit_limit" style="display: none">
                                        <label class="bs-form-label" for="Alcohol_benefit_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Alcohol_benefit_limit" id="Alcohol_benefit_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Alcohol_benefit_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div> -->

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Parental_care_benefit">Parental Care Benefit <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Parental_care_benefit_div')" (mouseleave)="mouseLeave($event, 'Parental_care_benefit_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Parental_care_benefit_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Parental Care Benefit</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Parental_care_benefit" formControlName="Parental_care_benefit" name="Parental_care_benefit" value="No" (change)="checkradio('divParental_care_benefit_limit', 'NotCovered'); green_red_change('Parental_care_benefit', 'no')" />
                                            <label for="radio-two_Parental_care_benefit" id="Parental_care_benefit_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Parental_care_benefit" formControlName="Parental_care_benefit" name="Parental_care_benefit" value="Yes" (change)="checkradio('divParental_care_benefit_limit', 'Covered'); green_red_change('Parental_care_benefit', 'yes')" />
                                            <label for="radio-one_Parental_care_benefit" id="Parental_care_benefit_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divParental_care_benefit_limit" style="display: none">
                                        <label class="bs-form-label" for="Parental_care_benefit_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Parental_care_benefit_limit" id="Parental_care_benefit_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Parental_care_benefit_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Terrorism_clause">Terrorism Clause <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Terrorism_clause_div')" (mouseleave)="mouseLeave($event, 'Terrorism_clause_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Terrorism_clause_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Terrorism Clause</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Terrorism_clause" formControlName="Terrorism_clause" name="Terrorism_clause" value="No" (change)="checkradio('divTerrorism_clause_limit', 'NotCovered'); green_red_change('Terrorism_clause', 'no')" />
                                            <label for="radio-two_Terrorism_clause" id="Terrorism_clause_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Terrorism_clause" formControlName="Terrorism_clause" name="Terrorism_clause" value="Yes" (change)="checkradio('divTerrorism_clause_limit', 'Covered'); green_red_change('Terrorism_clause', 'yes')" />
                                            <label for="radio-one_Terrorism_clause" id="Terrorism_clause_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-4 form-group" id="divTerrorism_clause_limit" style="display: none">
                                        <label class="bs-form-label" for="Terrorism_clause_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Terrorism_clause_limit" id="Terrorism_clause_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Terrorism_clause_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p>
                                    </div>
                                </div>

                                <div class="col-sm-12">
                                    <div class="row newothersField">

                                    </div>
                                </div>
                                <div class="col-sm-12 mt-3">
                                    <label class="bs-form-label" (click)="addOthersField()" style="cursor: pointer">
                                        <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                        <span style="color: #1489F3;">Add New Field</span>
                                    </label>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <!----------------------------->
                
                <!---- Waiting period Condition Accordian ---->
                <h5 class="bs-left-form-label accordheading waitingheading" (click)="waitingPeriodCondition()">Employee Topup and Spouse Cover
                    <img id="WPC_down_arrow" src="../../assets/img/common icons/arrow-down.png" class="arrowup" />
                    <img id="WPC_up_arrow" src="../../assets/img/common icons/arrow-up.png" class="arrowdown" />
                    <img src="../../assets/img/policy/waiting.png" class="headicon" />
                    <img id="waitingtick" src="../../assets/img/policy/tickicon.png" class="tickicon" alt="Icon"/>
                    <img id="waitingtick_error" src="../../assets/img/policy/errorpolicy.png" class="tickicon" alt="Icon" />
                    <div class="circular-progress" id="Progwaiting">
                        <div class="value-container" id="Valuewaiting">0%</div>
                    </div>
                    <p class="bs-p-light headspan">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p>
                </h5>
                <div id="waitingPeriodCondition_accord" class="accord_div">

                    <div class="row">                        

                        <div class="col-sm-12">
                            <div class="row">                                                               

                                <div class="col-sm-12 row form-group m-0 p-0">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Top_up_plan_under_accident_cover">Top up plan under accident cover? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Top_up_plan_under_accident_cover_div')" (mouseleave)="mouseLeave($event, 'Top_up_plan_under_accident_cover_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Top_up_plan_under_accident_cover_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Top up plan under accident cover</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Top_up_plan_under_accident_cover" formControlName="Top_up_plan_under_accident_cover" name="Top_up_plan_under_accident_cover" value="No" (change)="checkradio('divTop_up_plan_under_accident_cover_limit', 'NotCovered'); green_red_change('Top_up_plan_under_accident_cover', 'no')" />
                                            <label for="radio-two_Top_up_plan_under_accident_cover" id="Top_up_plan_under_accident_cover_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Top_up_plan_under_accident_cover" formControlName="Top_up_plan_under_accident_cover" name="Top_up_plan_under_accident_cover" value="Yes" (change)="checkradio('divTop_up_plan_under_accident_cover_limit', 'Covered'); green_red_change('Top_up_plan_under_accident_cover', 'yes')" />
                                            <label for="radio-one_Top_up_plan_under_accident_cover" id="Top_up_plan_under_accident_cover_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-12 form-group" id="divTop_up_plan_under_accident_cover_limit" style="display: none">
                                        <!-- <label class="bs-form-label" for="Top_up_plan_under_accident_cover_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Top_up_plan_under_accident_cover_limit" id="Top_up_plan_under_accident_cover_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Top_up_plan_under_accident_cover_limit_error" style="display:none; margin-bottom: -10px;;">
                                            This field is required 
                                        </p> -->

                                        <div class="row mt-3">                                                                

                                            <label class="bs-form-label">What is the basis of Accidental sum assured?</label>
            
                                            <div class="form-check col-sm-4 mt-3">
                                                <input type="radio" class="form-radio-input parentcheck" value="Multiple of Salary" formControlName="SI_type_topup" name="SI_type_topup" (click)="show_SI_div_topup('Multiple of Salary')" id="SI_type_topup" style="background: #fff" />
                                                <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Multiple of Salary</label>
                                            </div>                                                                                        
                                            <div class="form-check col-sm-4 mt-3">
                                                <input type="radio" class="form-radio-input parentcheck" value="Flat" formControlName="SI_type_topup" name="SI_type_topup" (click)="show_SI_div_topup('Flat')" id="SI_type_topup" style="background: #fff" />
                                                <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Flat</label>
                                            </div>
                                            <div class="form-check col-sm-4 mt-3">
                                                <input type="radio" class="form-radio-input parentcheck" value="Graded" formControlName="SI_type_topup" name="SI_type_topup" (click)="show_SI_div_topup('Graded')" id="SI_type_topup" style="background: #fff" />
                                                <label class="form-check-label bs-radio-label" style="margin-top: -3px;margin-left: 5px;">Graded</label>
                                            </div>                                
            
                                            <div id="div_multiple_of_salary_topup" style="display:none">
                                                
                                                <div class="col-sm-4 form-group mt-3 multiple_of_salary_topup" style="display:none">
                                                    <label class="bs-form-label" for="multiple_of_salary_topup">Define the multiple of salary? <span class="redstar">*</span></label>
                                                    <select class="form-select" formControlName="multiple_of_salary_topup" id="multiple_of_salary_topup">
                                                        <option value="" selected>Select</option>
                                                    </select>                            
                                                    <p class="error" id="multiple_of_salary_topup_error" style="display:none">
                                                        This field is required
                                                    </p>                                                    
                                                </div>  
                                                
                                                <div id="topupmsg" class="mx-3" style="display:none">
                                                    <label class="bs-form-label" >You have utilized the limit of Multiple of Salary i.e. 7</label>
                                                </div>
            
                                            </div>
            
                                            <div id="div_Flat_topup" style="display:none">
                                                
                                                <div class="col-sm-4 form-group mt-3">
                                                    <label class="bs-form-label" for="Define_flat_SI_topup">Define flat sum insured under group term life plan <span class="redstar">*</span></label>
                                                    <input type="text" class="form-control" formControlName="Define_flat_SI_topup" id="Define_flat_SI_topup" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                                    <p class="error" id="Define_flat_SI_topup_error" style="display:none">
                                                        This field is required
                                                    </p>
                                                </div>
            
                                            </div>
            
                                            <div id="div_Graded_topup" style="display:none">
            
                                                <div class="col-sm-6 form-group mt-3">
                                                    <label class="bs-form-label" for="SI_Junior_management_topup">Define graded group term life sum insured- Junior management <span class="redstar">*</span></label>
                                                    <input type="text" class="form-control" formControlName="SI_Junior_management_topup" id="SI_Junior_management_topup" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                                    <p class="error" id="SI_Junior_management_topup_error" style="display:none">
                                                        This field is required
                                                    </p>
                                                </div>
            
                                                <div class="col-sm-6 form-group mt-3">
                                                    <label class="bs-form-label" for="SI_Middle_management_topup">Define graded group term life sum insured- Middle management <span class="redstar">*</span></label>
                                                    <input type="text" class="form-control" formControlName="SI_Middle_management_topup" id="SI_Middle_management_topup" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                                    <p class="error" id="SI_Middle_management_topup_error" style="display:none">
                                                        This field is required
                                                    </p>
                                                </div>
            
                                                <div class="col-sm-6 form-group mt-3">
                                                    <label class="bs-form-label" for="SI_Senior_management_topup">Define graded group term life sum insured- Senior management <span class="redstar">*</span></label>
                                                    <input type="text" class="form-control" formControlName="SI_Senior_management_topup" id="SI_Senior_management_topup" (keypress)="isNumber($event)" (input)="isformat($event);" placeholder="Enter Amount" />
                                                    <p class="error" id="SI_Senior_management_topup_error" style="display:none">
                                                        This field is required
                                                    </p>
                                                </div>
            
                                                <div class="col-sm-12">
                                                    <div class="row newgradedtoptupField">
                
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 mt-3">
                                                    <label class="bs-form-label" (click)="addGradedFieldTopup()" style="cursor: pointer">
                                                        <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                                        <span style="color: #1489F3;">Add Amount</span>
                                                    </label>
                                                </div>                         
            
                                            </div>
            
                                        </div>

                                    </div>
                                </div>

                                <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                    <div class="col-sm-4 form-group">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="Can_spouse_Accidental_cover_be_bought">Can spouse Accidental cover be bought? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'Can_spouse_Accidental_cover_be_bought_div')" (mouseleave)="mouseLeave($event, 'Can_spouse_Accidental_cover_be_bought_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                            <div id="Can_spouse_Accidental_cover_be_bought_div" class="icon_div" style="display: none">
                                                <p class="bs-form-label_icon">Can spouse Accidental cover be bought</p>
                                            </div>
                                        </div>
                                        <div class="switch-field wFC">
                                            <input type="radio" id="radio-two_Can_spouse_Accidental_cover_be_bought" formControlName="Can_spouse_Accidental_cover_be_bought" name="Can_spouse_Accidental_cover_be_bought" value="No" (change)="checkradio('divCan_spouse_Accidental_cover_be_bought_limit', 'NotCovered'); green_red_change('Can_spouse_Accidental_cover_be_bought', 'no')" />
                                            <label for="radio-two_Can_spouse_Accidental_cover_be_bought" id="Can_spouse_Accidental_cover_be_bought_no" class="bs-form-label">No</label>
                                            <input type="radio" id="radio-one_Can_spouse_Accidental_cover_be_bought" formControlName="Can_spouse_Accidental_cover_be_bought" name="Can_spouse_Accidental_cover_be_bought" value="Yes" (change)="checkradio('divCan_spouse_Accidental_cover_be_bought_limit', 'Covered'); green_red_change('Can_spouse_Accidental_cover_be_bought', 'yes')" />
                                            <label for="radio-one_Can_spouse_Accidental_cover_be_bought" id="Can_spouse_Accidental_cover_be_bought_yes" class="bs-form-label">Yes</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-12 form-group" id="divCan_spouse_Accidental_cover_be_bought_limit" style="display: none">
                                        <!-- <label class="bs-form-label" for="Can_spouse_Accidental_cover_be_bought_limit">If Yes then specify the limit <span class="redstar">*</span></label>
                                        <input type="text" class="form-control" formControlName="Can_spouse_Accidental_cover_be_bought_limit" id="Can_spouse_Accidental_cover_be_bought_limit" (keypress)="isNumber($event)" (input)="isformat($event)" />
                                        <p class="error" id="Can_spouse_Accidental_cover_be_bought_limit_error" style="display:none; margin-bottom: -10px;">
                                            This field is required 
                                        </p> -->

                                        <div class="row mt-3">
                                        
                                            <div class="col-sm-4 form-group">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="AD">AD <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'AD_div')" (mouseleave)="mouseLeave($event, 'AD_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                                    <div id="AD_div" class="icon_div" style="display: none">
                                                        <p class="bs-form-label_icon">AD</p>
                                                    </div>
                                                </div>
                                                <div class="switch-field wFC">
                                                    <input type="radio" id="radio-two_AD" formControlName="AD" name="AD" value="No" (change)="green_red_change('AD', 'no')" />
                                                    <label for="radio-two_AD" id="AD_no" class="bs-form-label">No</label>
                                                    <input type="radio" id="radio-one_AD" formControlName="AD" name="AD" value="Yes" (change)="green_red_change('AD', 'yes')" />
                                                    <label for="radio-one_AD" id="AD_yes" class="bs-form-label">Yes</label>                                            
                                                </div>
                                            </div>

                                            <div class="col-sm-4 form-group">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="PPD">PPD <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'PPD_div')" (mouseleave)="mouseLeave($event, 'PPD_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                                    <div id="PPD_div" class="icon_div" style="display: none">
                                                        <p class="bs-form-label_icon">PPD</p>
                                                    </div>
                                                </div>
                                                <div class="switch-field wFC">
                                                    <input type="radio" id="radio-two_PPD" formControlName="PPD" name="PPD" value="No" (change)="green_red_change('PPD', 'no')" />
                                                    <label for="radio-two_PPD" id="PPD_no" class="bs-form-label">No</label>
                                                    <input type="radio" id="radio-one_PPD" formControlName="PPD" name="PPD" value="Yes" (change)="green_red_change('PPD', 'yes')" />
                                                    <label for="radio-one_PPD" id="PPD_yes" class="bs-form-label">Yes</label>                                            
                                                </div>
                                            </div>

                                            <div class="col-sm-4 form-group">
                                                <div class="form-group">
                                                    <label class="bs-form-label" for="TTD">TTD <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'TTD_div')" (mouseleave)="mouseLeave($event, 'TTD_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                                    <div id="TTD_div" class="icon_div" style="display: none">
                                                        <p class="bs-form-label_icon">TTD</p>
                                                    </div>
                                                </div>
                                                <div class="switch-field wFC">
                                                    <input type="radio" id="radio-two_TTD" formControlName="TTD" name="TTD" value="No" (change)="green_red_change('TTD', 'no')" />
                                                    <label for="radio-two_TTD" id="TTD_no" class="bs-form-label">No</label>
                                                    <input type="radio" id="radio-one_TTD" formControlName="TTD" name="TTD" value="Yes" (change)="green_red_change('TTD', 'yes')" />
                                                    <label for="radio-one_TTD" id="TTD_yes" class="bs-form-label">Yes</label>                                            
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>                                

                            </div>
                        </div>

                    </div>
                </div>
                <!----------------------------->

                <div class="row">

                    <!-- <hr class="my-3"> -->
                    <div>
                        <button (click)="next(3);" class="btn btn-primary" style="float: right;">Save & Continue</button>
                        <!-- <button (click)="next(3);" class="btn btn-primary" *ngIf="stepper_flag == false" style="float: right;">Finish</button> -->
                        <button (click)="previous()" class="btn btn-primary m-3 mt-0" style="float: right;
    background: #fff;
    border: solid 1px #4E0099;
    color: #4E0099;
    background: none !important;">Previous</button>
                    </div>
                </div>

                </form>
            </div>

            <div id="test-l-4" class="content mt-3">                

                <form [formGroup]="documentUpload">
                <h3 class="bs-title">Document upload</h3>
                <!-- <p class="bs-p-normal">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                <hr>
                <div class="row">
                    <div class="col-sm-4">
                        <h5 class="bs-left-form-label">Document upload</h5>
                        <!-- <p class="bs-p-light">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique, nobis voluptatum laborum neque</p> -->
                    </div>
                    <div class="col-sm-8">
                        <!-- <div *ngIf="stepper_flag == false">
                            As New policy is selected no need for uploading any doc, directly click on Finish
                        </div> -->
                        <div class="row">                                                       

                            <div class="col-sm-12" *ngIf="stepper_flag == true">
                                <div class="form-group mt-3">
                                    <label class="bs-form-label" for="policy_document">Policy Document</label>
                                    <!-- <input type="file" class="form-control" formControlName="policy_document" (change)="policy_document_change($event)" id="file" enctype="multipart/form-data" /> -->

                                    <label for="file_field5" id="custom-file-upload_policy_document" class="custom-file-upload form-control" style="text-align:center; cursor:pointer">
                                        <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_policy_document" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>
                                    </label>
                                    <input id="file_field5" type="file" (change)="policy_document_change($event)" accept=".xlsx, .xls, .csv, application/pdf" enctype="multipart/form-data" style="display: none" />

                                </div>
                                <small style="font-size: 12px; color:#605D62" id="small_policy_document">Upload only PDF files (Max size 4MB)</small>
                            </div>

                            <div class="col-sm-12">
                                <div class="form-group mt-3">
                                    <label class="bs-form-label" for="member_data">Member Data Upload <span class="redstar">*</span></label>
                                    <!-- <input type="file" class="form-control" formControlName="member_data" (change)="member_data_change($event)" id="file" enctype="multipart/form-data" /> -->

                                    <label for="file_field6" id="custom-file-upload_member_data" class="custom-file-upload form-control" style="text-align:center; cursor:pointer">
                                        <img src="../../assets/img/policy/pin.png" style="width: 12px;">&nbsp; <span id="selectFile_member_data" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>
                                    </label>

                                    <input id="file_field6" type="file" (change)="member_data_change($event)" formControlName="member_data" accept=".xlsx, .xls, .csv, application/pdf" enctype="multipart/form-data" style="display: none"
                                    [ngClass]="{'control-red': documentUploadSubmitted && getdocumentUploadFormControls.member_data.errors}"
                                    required />                           
                                    <ng-container
                                        *ngIf="documentUploadSubmitted && getdocumentUploadFormControls.member_data.errors">
                                        <p class="error">
                                        Please upload member data
                                        </p>
                                    </ng-container>

                                </div>
                                <small style="font-size: 12px; color:#605D62" id="small_member_data">Upload only .xlsx, .xls, .csv files (Max size 4MB)</small>
                                <span style="font-size:12px ; float:right; color: #1489F3; cursor: pointer" (click)="downloadMemberdata()">Download Sample File</span>
                            </div>

                            <div class="col-sm-12 row form-group m-0 p-0 mt-3">
                                <div class="col-sm-6 form-group">
                                    <div class="form-group">
                                        <label class="bs-form-label" for="claims_in_last_five_years">Do you have claims in last 5 Years? <i class="bi bi-exclamation-circle" (mouseenter)="mouseEnter($event,'claims_in_last_five_years_div')" (mouseleave)="mouseLeave($event, 'claims_in_last_five_years_div')" style="cursor: pointer; width:20px; height:20px; transform: rotate(-180deg)"></i></label>
                                        <div id="claims_in_last_five_years_div" class="icon_div" style="display: none">
                                            <p class="bs-form-label_icon">Do you have claims in last 5 Years?</p>
                                        </div>
                                    </div>
                                    <div class="switch-field wFC">
                                        <input type="radio" id="radio-two_claims_in_last_five_years" formControlName="claims_in_last_five_years" name="claims_in_last_five_years" value="No" (change)="checkradio('divclaims_in_last_five_years_limit', 'NotCovered'); green_red_change('claims_in_last_five_years', 'no')" />
                                        <label for="radio-two_claims_in_last_five_years" id="claims_in_last_five_years_no" class="bs-form-label">No</label>
                                        <input type="radio" id="radio-one_claims_in_last_five_years" formControlName="claims_in_last_five_years" name="claims_in_last_five_years" value="Yes" (change)="checkradio('divclaims_in_last_five_years_limit', 'Covered'); green_red_change('claims_in_last_five_years', 'yes')" />
                                        <label for="radio-one_claims_in_last_five_years" id="claims_in_last_five_years_yes" class="bs-form-label">Yes</label>                                            
                                    </div>
                                </div>
                                <div class="col-sm-6 form-group" id="divclaims_in_last_five_years_limit" style="display: none">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label class="bs-form-label" for="claims_in_last_five_years">Upload Document</label>

                                            <label for="file_field7" id="custom-file-claims_in_last_five_years" class="custom-file-upload form-control" style="text-align:center; cursor:pointer">
                                                <img src="../../assets/img/policy/pin.png" style="width: 12px;" />&nbsp; <span id="selectFile_claims_in_last_five_years" style="color:#4E0099; font-weight: 600; text-align:center">Add File</span>
                                            </label>
                                            <input id="file_field7" type="file" (change)="claims_in_last_five_years_change($event)" accept=".xlsx, .xls, .csv, application/pdf" enctype="multipart/form-data" style="display: none" />

                                        </div>
                                        <small style="font-size: 12px; color:#605D62" id="small_claims_in_last_five_years">Upload only PDF files (Max size 4MB)</small>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-sm-12">
                                <div class="row newuploadField">

                                </div>
                            </div>

                            <div class="col-sm-12 mt-3">
                                <label class="bs-form-label" (click)="addUploadField()" style="cursor: pointer">
                                    <img src="../../assets/img/policy/add.png" style="width: 20px; margin-top: -4px;" />&nbsp;
                                    <span style="color: #1489F3;">Add New Upload Field</span>
                                </label>
                            </div> -->


                        </div>
                    </div>

                    <hr class="my-3">
                    <div>
                        <button (click)="next(4);" class="btn btn-primary" style="float: right;">Finish</button>
                        <button (click)="previous()" class="btn btn-primary m-3 mt-0" style="float: right;
    background: #fff;
    border: solid 1px #4E0099;
    color: #4E0099;
    background: none !important;">Previous</button>
                    </div>
                </div>
                </form>
            </div>
    </div>

    <!---- Thank you Popup ---->
    <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleThankyou}">
        <div class="modal-dialog" role="document">
            <div class="modal-content" style="width: 80%; padding-bottom: 10px; margin: auto; top:40px">
                <!-- <div class="modal-header">
                    <h4 class="modal-title">Hurray!!</h4>
                </div> -->
                <div class="modal-body">                    
                    <div class="row">

                        <div class="col-sm-12">
                            <img src="../../assets/img/policy/hurray.jpg" class="img-fluid mb-3" style="width:50%; margin: auto; display: block; margin-bottom: 0px !important;" />
                        </div>

                        <div class="col-sm-12">
                            
                            <h5 style="text-align: center; font-weight: bold;">Hooray!!</h5>
                            <h6 class="mt-3 text-center" style="font-size: 14px;"> Plan created successfully.</h6>
                            <h6 class="mt-2 text-center" style="font-weight: 600; font-size: 14px;">Your Plan ID : {{proposal_number_show}}</h6>

                            <div class="form-group mt-3">
                                <label class="bs-form-label text-center" style="margin: auto; display: block;"> Enter Plan Name </label>
                                <input type="text" class="form-control" id="plan_name" name="plan_name" />
                                <p class="error" id="plan_name_error" style="display: none">
                                    This field is required
                                </p>
                            </div>

                        </div>                

                    </div>                    
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="closePopup()" style="margin: auto;"> Submit & Close</button>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- header -->
<app-web-menu-bar></app-web-menu-bar>
<!-- header-end -->

<!-- main-area -->
<main style="background-color: #fff !important;">
    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        
       
    <div class="main_title">
        <span>Automation</span>
    </div>

    <div class="container mt-3">
        <div class="accordion" id="accordionExample">

            <div class="card">
                <div class="card-header" id="headingChatbot">
                    <h2 class="mb-0">
                        <button class="btn btn-link accordion-button" type="button" data-toggle="collapse"
                            data-target="#collapseChatbot" aria-expanded="true" aria-controls="collapseChatbot">
                            AI-Powered WhatsApp Chatbot
                        </button>
                    </h2>
                </div>
        
                <div id="collapseChatbot" class="collapse show" aria-labelledby="headingChatbot" data-parent="#accordionExample">
                    <div class="card-body">
                        <p>Transform your customer support with our AI-driven WhatsApp chatbot, offering instant, personalized, and seamless interactions. Key features include:</p>
                        <div class="card">
                            <h3>Key Features</h3>
                        </div>
                        <div class="card">
                            <h4>24/7 Automated Support</h4>
                            <p>Provide round-the-clock assistance without human intervention.</p>
                        </div>
                        <div class="card">
                            <h4>Quick Query Resolution</h4>
                            <p>Address customer inquiries swiftly and efficiently.</p>
                        </div>
                        <div class="card">
                            <h4>Claim and Policy Tracking</h4>
                            <p>Enable customers to monitor claims and policies effortlessly.</p>
                        </div>
                        <div class="card">
                            <h4>Personalized Assistance</h4>
                            <p>Tailor support based on individual customer needs.</p>
                        </div>
                        <div class="card">
                            <h4>User-Friendly Experience</h4>
                            <p>Ensure smooth and intuitive interactions.</p>
                        </div>
                        <p>Elevate your customer support with our chatbot, delivering exceptional service and driving business success.</p>
                    </div>
                </div>
            </div>
        
            <div class="card">
                <div class="card-header" id="headingIVR">
                    <h2 class="mb-0">
                        <button class="btn btn-link accordion-button collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseIVR" aria-expanded="false" aria-controls="collapseIVR">
                            IVR & Voice Bot
                        </button>
                    </h2>
                </div>
                <div id="collapseIVR" class="collapse" aria-labelledby="headingIVR" data-parent="#accordionExample">
                    <div class="card-body">
                        <p>Interactive Voice Response (IVR) systems and voice bots are revolutionizing employee benefits management by automating interactions and enhancing engagement. Key benefits include:</p>
                        <div class="card">
                            <h3>Key Benefits</h3>
                        </div>
                        <div class="card">
                            <h4>24/7 Access to Information</h4>
                            <p>Employees can access details about their benefits and claims anytime, reducing wait times and boosting satisfaction.</p>
                        </div>
                        <div class="card">
                            <h4>Self-Service Capabilities</h4>
                            <p>Voice bots guide employees through common inquiries, such as checking claim status, without human intervention.</p>
                        </div>
                        <div class="card">
                            <h4>Streamlined Enrollment Process</h4>
                            <p>Assist employees with step-by-step guidance during benefits enrollment, reducing the administrative burden on HR teams.</p>
                        </div>
                        <div class="card">
                            <h4>Personalized Experiences</h4>
                            <p>Tailored interactions based on employee data enhance the overall experience, providing relevant benefits recommendations.</p>
                        </div>
                        <div class="card">
                            <h4>Efficient Claims Management</h4>
                            <p>Employees can submit and track claims through IVR, receiving instant updates to reduce anxiety.</p>
                        </div>
                        <div class="card">
                            <h4>Enhanced Communication</h4>
                            <p>Automated reminders and feedback collection keep employees informed and engaged.</p>
                        </div>
                        <div class="card">
                            <h4>Cost Efficiency</h4>
                            <p>Automating routine inquiries reduces call center/servicing costs and allows for scalable support.</p>
                        </div>
                        <div class="card">
                            <h4>Improved Data Collection</h4>
                            <p>IVR systems gather insights on employee inquiries, informing future strategies for benefits offerings.</p>
                        </div>
                        <p>Transform your employee benefits management with IVR and voice bot technologies for a more efficient and engaging experience.</p>
                    </div>
                </div>
            </div>
        
            <div class="card">
                <div class="card-header" id="headingRPA">
                    <h2 class="mb-0">
                        <button class="btn btn-link accordion-button collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseRPA" aria-expanded="false" aria-controls="collapseRPA">
                            Robotic Process Automation (RPA)
                        </button>
                    </h2>
                </div>
                <div id="collapseRPA" class="collapse" aria-labelledby="headingRPA" data-parent="#accordionExample">
                    <div class="card-body">
                        <p>Robotic Process Automation (RPA) is transforming employee benefits insurance by automating repetitive, rule-based tasks, streamlining operations, and enhancing employee experiences. Key features include:</p>
                        <div class="card">
                            <h3>Key Features</h3>
                        </div>
                        <div class="card">
                            <h4>Claims Processing Automation</h4>
                            <p>Automate data entry and validation to reduce errors and speed up claims processing. Bots can verify employee eligibility efficiently.</p>
                        </div>
                        <div class="card">
                            <h4>Benefits Enrollment</h4>
                            <p>Handle automated enrollment workflows and send notifications about deadlines and options, reducing HR’s administrative burden.</p>
                        </div>
                        <div class="card">
                            <h4>Data Management and Reporting</h4>
                            <p>Integrate data from multiple systems for a centralized view and generate automated reports on claims utilization and employee participation.</p>
                        </div>
                        <div class="card">
                            <h4>Compliance and Audit Support</h4>
                            <p>Automate regulatory compliance checks and maintain detailed audit trails for transparency.</p>
                        </div>
                        <div class="card">
                            <h4>Employee Communication</h4>
                            <p>Use RPA-driven chatbots for instant support on common inquiries and automate personalized communication to enhance engagement.</p>
                        </div>
                        <div class="card">
                            <h4>Workflow Automation</h4>
                            <p>Automate routine tasks like tracking claims status and streamlining approval processes, allowing HR to focus on higher-value activities.</p>
                        </div>
                        <p>Embrace RPA to revolutionize your employee benefits management!</p>
                    </div>
                </div>
            </div>
        
        </div>        
    </div>
    
</main>
<!-- main-area-end -->
 
<!-- footer -->
<app-web-footer></app-web-footer>
<!-- footer-end -->


<!-- header -->
<app-web-menu-bar></app-web-menu-bar>
<!-- header-end -->

<!-- main-area -->
<main style="background-color: #fff !important;">
    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        
    
    <div class="main_title">
        <span>Affinity</span>
    </div>

    <div class="container mt-3">
        <div class="accordion" id="accordionExample">
            <div class="card">
                <div class="card-header" id="headingAffinity">
                    <h2 class="mb-0">
                        <button class="btn btn-link accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapseAffinity" aria-expanded="false" aria-controls="collapseAffinity">
                            Affinity Solution - Tailored Insurance for Exclusive Groups
                        </button>
                    </h2>
                </div>
                <div id="collapseAffinity" class="collapse show" aria-labelledby="headingAffinity" data-parent="#accordionExample">
                    <div class="card-body">
                        <p>Our Affinity Solution offers customized insurance products designed for affinity groups like associations, unions, alumni networks, and professional organizations. We provide:</p>
                        <ul class="benefits-list">
                            <li>Personalized Coverage Options: Tailored plans to meet individual and group needs.</li>
                            <li>Competitive Rates: Budget-friendly pricing for exceptional value.</li>
                            <li>Streamlined Enrollment: Easy and efficient sign-up processes.</li>
                            <li>Dedicated Support: Specialized assistance for ongoing inquiries.</li>
                        </ul>
                        <p>Enhance your group’s benefits with our bespoke insurance solutions, delivering customized coverage and outstanding value.</p>
                    </div>
                </div>
            </div>                          
        </div>
    </div>        
    
</main>
<!-- main-area-end -->
 
<!-- footer -->
<app-web-footer></app-web-footer>
<!-- footer-end -->
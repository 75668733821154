<!-- header -->
<app-web-menu-bar></app-web-menu-bar>
<!-- header-end -->

<!-- main-area -->
<main style="background-color: #fff !important;">
    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>                        

    <div class="main_title">
        <span>Customized Solution</span>
    </div>

    <div class="container mt-3">
        <div class="accordion" id="accordionExample">
        
            <div class="card">
                <div class="card-header" id="headingPolicyAdmin">
                    <h2 class="mb-0">
                        <button class="btn btn-link accordion-button" type="button" data-toggle="collapse" data-target="#collapsePolicyAdmin" aria-expanded="false" aria-controls="collapsePolicyAdmin">
                            Policy Administration System
                        </button>
                    </h2>
                </div>
                <div id="collapsePolicyAdmin" class="collapse show" aria-labelledby="headingPolicyAdmin" data-parent="#accordionExample">
                    <div class="card-body">
                        <!-- Content can be added here -->
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header" id="headingPolicyBooking">
                    <h2 class="mb-0">
                        <button class="btn btn-link accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapsePolicyBooking" aria-expanded="false" aria-controls="collapsePolicyBooking">
                            Policy & Endorsement Booking System
                        </button>
                    </h2>
                </div>
                <div id="collapsePolicyBooking" class="collapse" aria-labelledby="headingPolicyBooking" data-parent="#accordionExample">
                    <div class="card-body">
                        <!-- Content can be added here -->
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header" id="headingQuoteModule">
                    <h2 class="mb-0">
                        <button class="btn btn-link accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapseQuoteModule" aria-expanded="false" aria-controls="collapseQuoteModule">
                            Quote Module
                        </button>
                    </h2>
                </div>
                <div id="collapseQuoteModule" class="collapse" aria-labelledby="headingQuoteModule" data-parent="#accordionExample">
                    <div class="card-body">
                        <!-- Content can be added here -->
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header" id="headingPropertyRisk">
                    <h2 class="mb-0">
                        <button class="btn btn-link accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapsePropertyRisk" aria-expanded="false" aria-controls="collapsePropertyRisk">
                            Property & Construction Risk Solutions
                        </button>
                    </h2>
                </div>
                <div id="collapsePropertyRisk" class="collapse" aria-labelledby="headingPropertyRisk" data-parent="#accordionExample">
                    <div class="card-body">
                        <!-- Content can be added here -->
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header" id="headingMarineSolutions">
                    <h2 class="mb-0">
                        <button class="btn btn-link accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapseMarineSolutions" aria-expanded="false" aria-controls="collapseMarineSolutions">
                            Marine Solutions
                        </button>
                    </h2>
                </div>
                <div id="collapseMarineSolutions" class="collapse" aria-labelledby="headingMarineSolutions" data-parent="#accordionExample">
                    <div class="card-body">
                        <!-- Content can be added here -->
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header" id="headingBusinessOps">
                    <h2 class="mb-0">
                        <button class="btn btn-link accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapseBusinessOps" aria-expanded="false" aria-controls="collapseBusinessOps">
                            Business Operations Solutions
                        </button>
                    </h2>
                </div>
                <div id="collapseBusinessOps" class="collapse" aria-labelledby="headingBusinessOps" data-parent="#accordionExample">
                    <div class="card-body">
                        <!-- Content can be added here -->
                    </div>
                </div>
            </div>
                
        </div>
    </div>
    
</main>
<!-- main-area-end -->
 
<!-- footer -->
<app-web-footer></app-web-footer>
<!-- footer-end -->
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-schedule-demo',
  templateUrl: './schedule-demo.component.html',
  styleUrls: ['./schedule-demo.component.css']
})
export class ScheduleDemoComponent implements OnInit {

  show = false;
  fullScreen = true;
  template = ``;

  constructor() { }

  ngOnInit(): void {
    this.show = true;
    this.fullScreen = true;
    this.template = ``
    setTimeout(() => {
        this.show = false
    }, 2000);
    $('html, body').animate({ scrollTop: 0 }, 'slow');
  }

}

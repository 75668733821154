<head>
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.0.8/css/all.css">
</head>
<!-- header -->
<header class="header-area">
    <div class="header-top second-header d-none d-md-block">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-3 col-md-3 d-none d-lg-block">
                </div>
                <div class="col-lg-2 col-md-8 d-none  d-md-block">
                    <div class="header-cta">
                        <ul>
                            <li>
                                <img src="../../assets/website/icon/email.png"
                                    style="width: 20px; margin-right: 10px;" />
                                <span style="font-size: 12px;">info&#64;neodigit.in</span>
                            </li>
                            <!-- <li>
                                <img src="../../assets/website/icon/telephone.png"
                                    style="width: 20px; margin-right: 10px;" />
                                <span style="font-size: 12px;">+8 12 3456897</span>
                            </li> -->
                        </ul>
                    </div>
                </div>
                <div class="col-lg-5 col-md-3 d-none d-lg-block">
                    <div class="header-social text-right">
                        <span>
                            <a href="#" title="Facebook"><i class="fab fa-facebook"></i></a>
                            <a href="#" title="Twitter"><i class="fab fa-twitter"></i></a>
                            <a href="#" title="LinkedIn"><i class="fab fa-linkedin-in"></i></a>                            
                        </span>
                        <!--  /social media icon redux -->                        
                    </div>                    
                </div>
                <div class="col-lg-2 col-md-8 d-none  d-md-block">
                    <a routerLink="/login" class="top-btn" href="#" style=" width: 136px;">Sign In <i class="fas fa-chevron-right"></i></a>                        
                </div>                

            </div>
        </div>
    </div>
    <div id="header-sticky" class="menu-area">
        <div class="container">
            <div class="second-menu">
                <div class="row align-items-center">
                    <div class="col-xl-2 col-lg-2">
                        <div class="logo">
                            <a routerLink="/home" href="#"><img src="../../assets/homelogo.png" alt="logo"></a>
                        </div>
                    </div>
                    <div class="col-xl-10 col-lg-8">
                        <div class="main-menu text-right pr-15">
                            <nav id="mobile-menu">
                                <ul>
                                    <li class="has-sub">
                                        <a routerLink="/home" href="#">Home</a>
                                    </li>
                                    <li><a routerLink="/about-us" href="#">About Us</a></li>                                    
                                    <li><a routerLink="/schedule-demo" href="#">Schedule A Demo</a></li>
                                    
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <!-- <div class="col-xl-2 col-lg-2 d-none d-lg-block">
                        <a routerLink="/login" class="top-btn" href="#" style=" width: 136px;">Sign In <i class="fas fa-chevron-right"></i></a>                        
                    </div> -->
                    <div class="col-12">
                        <div class="mobile-menu"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- header-end -->
<!-- main-area -->
<main style="background-color: #fff !important;">
    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        

    <!-- breadcrumb-area -->
    <section class="breadcrumb-area d-flex align-items-center" style="background-image:url(../../assets/website/bg/header-bg.png)">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
                    <div class="breadcrumb-wrap text-center">
                        <div class="breadcrumb-title mb-30">
                            <h2>About Us</h2>                                    
                        </div>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">About Us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- breadcrumb-area-end -->
     
    <!-- about-area -->
    <section id="about" class="about-area about-p mt-100 pb-80 p-relative" style="background-image:url(../../assets/website/an-bg/an-bg03.png); background-size: contain; background-repeat: no-repeat;background-position: center center;">
        <div class="container">
            <div class="row align-items-center">

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="s-about-img p-relative">
                        <img src="../../../assets/website/about_us.png" style="margin: auto; display: block" alt="img">                        
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="about-content s-about-content pl-30">
                        <div class="section-title">
                            <span>About Us</span>
                        </div>
                        <p>NeoDigit is backed by a team of industry veterans and technological experts with over 21 years of combined experience in insurance and technology. Founded in August 2022, our company is driven by a clear mission: to revolutionize the insurance industry with cutting-edge technological solutions tailored for small and medium-sized enterprises (SMEs).</p>

                        <p>At NeoDigit, we harness the transformative power of software engineering, artificial intelligence (AI), machine learning (ML), and cloud computing to deliver innovative solutions that empower SMEs to thrive. Our goal is to make these sophisticated technologies accessible and affordable, eliminating the significant financial barriers that often accompany them.</p>
                        <p>To achieve this, we've developed a flexible <b>'Pay As You Use'</b> model, minimizing initial setup costs and allowing clients to pay only for the services they use. This cost-effective approach ensures scalability and adaptability, enabling SMEs to compete in a rapidly evolving industry landscape.</p>
                        <p>Through our commitment to affordability, flexibility, and innovation, NeoDigit aims to democratize access to high-tech solutions, empowering SMEs to succeed and driving progress in the insurance sector.</p>

                    </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 my-5">
                    <div class="about-content s-about-content pl-30">
                        <!-- <div class="section-title">
                            <span>Leadership</span>
                        </div> -->
                        
                        <div class="leadership">
                            <div class="member">
                                <img src="../../../assets/img/common icons/user.png" alt="Giriraj Singh">
                                <h3><b>Giriraj Singh</b></h3>
                                <p><strong>Giriraj Singh</strong> is an IIMK Alumni & Visionary Insurance Leader with over 21+ years of experience, recognized for his expertise, innovative approach, and strategic leadership. His illustrious career spans esteemed organizations, including Marsh, Prudent, WTW, Narayan Health, Samvedana Hospital, and Prakash Hospital, where he has driven growth, expansion, implemented cutting-edge technology solutions, enhanced customer satisfaction and experience.</p>
                                <p>With a deep understanding of insurance products, including benefits consulting and insurance analytics, he excels in innovative solutions for clients, strategic leadership, and forward-thinking approach. As a trailblazer in the industry, his commitment to innovation, strategic leadership, and thought leadership continues to shape the future of insurance, driving progress and excellence.</p>
                            </div>
                            
                            <div class="team-title">
                                <h2 style="position: relative;
                                top: 350px;
                                font-weight: bold;">Leadership</h2>
                            </div>
                            
                            <div class="member">
                                <img src="../../../assets/img/common icons/user.png" alt="Durgesh Kabra">
                                <h3><b>Durgesh Kabra</b></h3>
                                <p><strong>Durgesh Kabra</strong> is a highly accomplished technocrat with 15+ years of experience in software design, development, and leadership. Proven expertise in software design and development, system analysis, planning, execution, performance tuning, testing, and release/build management, training and mentoring, and customer relationship management.</p>
                                <p class="highlight"><strong>Programming languages:</strong> Java/J2EE, Spring/Hibernate, SOA/Restful Web services</p>
                                <p class="highlight"><strong>Frameworks:</strong> JSF, Struts, ATG</p>
                                <p class="highlight"><strong>Web development:</strong> HTML, XHTML, DHTML, XML, XSLT, CSS, JSP, AJAX, DOJO, JSON, JavaScript</p>
                                <p class="highlight"><strong>Data management:</strong> XML parsing (DOM and SAX), XSLT transformation, SQL, PL/SQL, Oracle, MySQL</p>
                                <p class="highlight"><strong>Application servers:</strong> Apache Tomcat, JBoss Server, WebSphere, WebLogic</p>   
                                <!-- <p>Durgesh Kabra is a seasoned technical lead with a strong track record of delivering complex projects and leading high-performing teams.</p> -->
                            </div>
                        </div>

                    </div>
                </div>
                
                <div class="col-lg-6 col-md-12 col-sm-12 my-5">
                    <div class="about-content s-about-content pl-30">
                        <div class="section-title">
                            <span>Why NeoDigit?  - Optimize Your Budget with Us!</span>
                        </div>
                        <p>Say goodbye to skyrocketing technology costs! At NeoDigit, we specialize in significantly cutting your monthly expenses on maintaining and upgrading your tech infrastructure. Our innovative solutions and flexible pricing models ensure you stay ahead of the curve without stretching your budget.</p>

                        <p>With NeoDigit, you can</p>
                        <ul>
                            <li class="highlight"><b>Reduce Operational Expenses:</b> Slash your technology costs.</li>
                            <li class="highlight"><b>Avoid Costly Upgrades:</b> Eliminate frequent, expensive updates and maintenance.</li>
                            <li class="highlight"><b>Scale Seamlessly:</b> Adjust your tech needs without financial strain.</li>
                            <li class="highlight"><b>Focus on Growth:</b> Concentrate on expanding your business, not on budget constraints.</li>
                        </ul>                        

                        <p>Discover how NeoDigit can streamline your technology requirements & expenses and enhance your bottom line.
                            Contact us today to learn more! - info&#64;neodigit.in
                        </p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="s-about-img p-relative">
                        <img src="../../../assets/website/why_us.jpg" style="margin: auto; display: block; width: 100%;" alt="img">                        
                    </div>
                </div>                

                <!-- <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="about-content s-about-content pl-30">
                        <div class="section-title">
                            <span>Vision</span>
                        </div>
                        <p>At NeoDigit, our vision is to transform the insurance industry with cutting-edge technology solutions, empowering businesses to excel in a rapidly evolving landscape.</p>
                        <p>We envision a future where:</p>
                        <ul>
                            <li class="highlight"><b>Innovation and Expertise Converge:</b> Delivering exceptional support through the latest advancements and deep industry knowledge.</li>
                            <li class="highlight"><b>Timely Solutions Drive Growth:</b> Providing effective solutions that propel business expansion.</li>
                            <li class="highlight"><b>Proactive Monitoring Ensures Seamless Operations:</b> Maintaining smooth operations through continuous oversight.</li>
                            <li class="highlight"><b>Personalized Guidance Fosters Excellence:</b> Offering tailored training and support to cultivate a culture of high performance.</li>
                            <li class="highlight"><b>Scalable Solutions Adapt to Change:</b> Ensuring our technology evolves with your needs.</li>
                        </ul>
                        <p>At NeoDigit, we're dedicated to shaping the future of insurance, one ground-breaking solution at a time. Join us in redefining the possibilities of Insurtech.</p>                        
                    </div>
                </div>
                
                <div class="col-lg-12 col-md-12 col-sm-12 mt-5">
                    <div class="about-content s-about-content pl-30">
                        <div class="section-title">
                            <span>Mission</span>
                        </div>
                        <p>Regardless of size, every insurance intermediary or corporate agent should have a strong online presence.</p>
                        <p>With a well-established digital footprint, you can:</p>
                        <ul>
                            <li class="highlight"><b>Operate 24/7:</b> Generate business and connect with clients around the clock, every day of the year.</li>
                            <li class="highlight"><b>Overcome Geographical Limits:</b> Reach potential clients beyond your local area, expanding your market.</li>
                            <li class="highlight"><b>Enhance Visibility:</b> Make it easier for clients to find and engage with your services.</li>
                        </ul>
                        <p>Harness the power of technology & the internet to boost your business and stay competitive.</p>
                        <img src="../../../assets/website/mission.png" style="margin: auto; display: block" alt="img">
                    </div>
                </div> -->

                <div class="section-title">
                    <span>Our Vision & Mission Statement</span>
                </div>

                <section class="vision-mission-section">
                    <div class="container vision-mission-container">
                        <div class="row">                            
                            <!-- Vision Card -->
                            <div class="col-lg-6 col-md-12 mb-4">
                                <div class="card">
                                    <div class="card-body">
                                        <h2 class="card-title">Vision</h2>
                                        <p>At NeoDigit, our vision is to transform the insurance industry with cutting-edge technology solutions, empowering businesses to excel in a rapidly evolving landscape.</p>
                                        <p>We envision a future where:</p>
                                        <ul>
                                            <li><strong>Innovation and Expertise Converge:</strong> Delivering exceptional support through the latest advancements and deep industry knowledge.</li>
                                            <li><strong>Timely Solutions Drive Growth:</strong> Providing effective solutions that propel business expansion.</li>
                                            <li><strong>Proactive Monitoring Ensures Seamless Operations:</strong> Maintaining smooth operations through continuous oversight.</li>
                                            <li><strong>Personalized Guidance Fosters Excellence:</strong> Offering tailored training and support to cultivate a culture of high performance.</li>
                                            <li><strong>Scalable Solutions Adapt to Change:</strong> Ensuring our technology evolves with your needs.</li>
                                        </ul>
                                        <p>At NeoDigit, we're dedicated to shaping the future of insurance, one ground-breaking solution at a time. Join us in redefining the possibilities of Insurtech.</p>
                                    </div>
                                </div>
                            </div>
            
                            <!-- Mission Card -->
                            <div class="col-lg-6 col-md-12 mb-4">
                                <div class="card">
                                    <div class="card-body">
                                        <h2 class="card-title">Mission</h2>
                                        <p>Regardless of size, every insurance intermediary or corporate agent should have a strong online presence.</p>
                                        <p>With a well-established digital footprint, you can:</p>
                                        <ul>
                                            <li><strong>Operate 24/7:</strong> Generate business and connect with clients around the clock, every day of the year.</li>
                                            <li><strong>Overcome Geographical Limits:</strong> Reach potential clients beyond your local area, expanding your market.</li>
                                            <li><strong>Enhance Visibility:</strong> Make it easier for clients to find and engage with your services.</li>
                                        </ul>
                                        <p>Harness the power of technology & the internet to boost your business and stay competitive.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>                
                
            </div>
        </div>
    </section>
    <!-- about-area-end -->
       
    
</main>
<!-- main-area-end -->
<!-- footer -->
<footer class="footer-bg footer-p" style="background-color: #fff;">
    <div class="overly"><img src="../../assets/website/an-bg/footer-bg.png" alt="rest"></div>
    <div class="footer-top pb-30" style="background-color: #ECF1FA;">
        <div class="container">
            <div class="row justify-content-between">

                <div class="col-xl-3 col-lg-3 col-sm-6">
                    <div class="footer-widget mb-30">
                        <div class="flog mb-35">
                            <a href="#"><img src="../../assets/homelogo.png" alt="logo"></a>
                        </div>
                        <div class="footer-text mb-20">
                            <p>NeoDigit is backed by a team of industry veterans and technological experts with over 21 years of combined experience in insurance and technology.</p>
                        </div>
                        <div class="footer-social">
                            <a href="#"><i class="fab fa-facebook-f"></i></a>
                            <a href="#"><i class="fab fa-twitter"></i></a>
                            <a href="#"><i class="fab fa-instagram"></i></a>
                            <a href="#"><i class="fab fa-google-plus-g"></i></a>
                        </div>
                    </div>
                </div>


                <div class="col-xl-2 col-lg-2 col-sm-6">
                    <div class="footer-widget mb-30">
                        <div class="f-widget-title">
                            <h5>Our Links</h5>
                        </div>
                        <div class="footer-link">
                            <ul>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> Home</a></li>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> Home</a></li>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> Schedule A Demo</a></li>                                                                
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-sm-6">
                    <div class="footer-widget mb-30">
                        <div class="f-widget-title">
                            <h5>Other Links</h5>
                        </div>
                        <div class="footer-link">
                            <ul>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> Home</a></li>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> About Us</a></li>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> Services</a></li>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> Project</a></li>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> Our Team</a></li>
                                <li><a href="#"><i class="fas fa-chevron-right"></i> Latest Blog</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-sm-6">
                    <div class="footer-widget mb-30">
                        <div class="f-widget-title">
                            <h5>Contact Us</h5>
                        </div>
                        <div class="footer-link">
                            <div class="f-contact">
                                <ul>
                                    <li>
                                        <img src="../../assets/img/common icons/to-do-list.png" style="width: 40px; height: 40px;" />
                                        <span>9999422119</span>
                                    </li>
                                    <li>
                                        <img src="../../assets/img/common icons/clientuser.png" style="width: 40px; height: 40px;" />
                                        <span><a href="mailto:info@neodigit.in">info&#64;neodigit.in</a></span>
                                    </li>
                                    <li>
                                        <img src="../../assets/img/common icons/product.png" style="width: 40px; height: 40px;" />
                                        <!-- <span>380 St Kilda Road, Melbourne<br>VIC 3004, Australia</span> -->
                                    </li>
                                </ul>

                            </div>


                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="copyright-wrap">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="copyright-text text-center">
                        <div class="container text-center">
                            <small class="text-black">Copyright &copy; 2023 NeoDigit | All rights reserved</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- footer-end -->
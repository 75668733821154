<!-- header -->
<app-web-menu-bar></app-web-menu-bar>
<!-- header-end -->

<!-- main-area -->
<main style="background-color: #fff !important;">
    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        
       
    <div class="main_title">
        <span>About Company</span>
    </div>

    <div class="container my-5">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="s-about-img p-relative">
                    <img src="../../../assets/website/about_us.png" style="margin: auto; display: block; width: 100%;" alt="img">                        
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="about-content s-about-content pl-30">
                    <div class="section-title mb-2">
                        <span><b>About Us</b></span>
                    </div>
                    <p>NeoDigit is backed by a team of industry veterans and technological experts with over 21 years of combined experience in insurance and technology. Founded in August 2022, our company is driven by a clear mission: to revolutionize the insurance industry with cutting-edge technological solutions tailored for small and medium-sized enterprises (SMEs).</p>

                    <p>At NeoDigit, we harness the transformative power of software engineering, artificial intelligence (AI), machine learning (ML) to deliver innovative solutions that empower SMEs to thrive. Our goal is to make these sophisticated technologies accessible and affordable, eliminating the significant financial barriers that often accompany them.</p>
                    <p>To achieve this, we've developed a flexible <b>'Pay As You Use'</b> model, minimizing initial setup costs and allowing clients to pay only for the services they use. This cost-effective approach ensures scalability and adaptability, enabling SMEs to compete in a rapidly evolving industry landscape.</p>
                    <p>Through our commitment to affordability, flexibility, and innovation, NeoDigit aims to democratize access to high-tech solutions, empowering SMEs to succeed and driving progress in the insurance sector.</p>

                </div>
            </div>
        </div>
    </div>    
    
</main>
<!-- main-area-end -->
 
<!-- footer -->
<app-web-footer></app-web-footer>
<!-- footer-end -->

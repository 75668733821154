import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-why-neodigit',
  templateUrl: './why-neodigit.component.html',
  styleUrls: ['./why-neodigit.component.css']
})
export class WhyNeodigitComponent implements OnInit {

  show = false;
  fullScreen = true;
  template = ``;
  
  constructor() { }

  ngOnInit(): void {
    this.show = true;
    this.fullScreen = true;
    this.template = ``
    setTimeout(() => {
        this.show = false
    }, 2000);
    $('html, body').animate({ scrollTop: 0 }, 'slow');
  }

}

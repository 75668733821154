<!-- header -->
<app-web-menu-bar></app-web-menu-bar>
<!-- header-end -->

<!-- main-area -->
<main style="background-color: #fff !important;">
    <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>

    <div class="main_title">
        <span>Schedule A Demo</span>
    </div>

    <section id="contact" class="contact-area contact-bg pt-100 pb-70 p-relative fix"
        style="background-image:url(../../assets/website/an-bg/an-bg11.png); background-size: cover;background-repeat: no-repeat;">
        <div class="container">

            <div class="row">
                <div class="col-lg-6">
                    <div>
                        <img src="../../../assets/website/demo.jpg" style="display: block; margin: auto; width: 100%;" alt="img">
                    </div>
                </div>
                <div class="col-lg-6">                    
                    <form action="#" class="contact-form">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="contact-field p-relative c-name mb-20">
                                    <input type="text" placeholder="Name of the company">
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="contact-field p-relative c-name mb-20">
                                    <input type="text" placeholder="Contact Person Name">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="contact-field p-relative c-email mb-20">
                                    <input type="text" placeholder="Company mail Id">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="contact-field p-relative c-subject mb-20">
                                    <input type="text" placeholder="Mobile No.">
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="contact-field p-relative c-message mb-45">
                                    <textarea name="message" id="message" cols="30" rows="10"
                                        placeholder="Remark"></textarea>
                                </div>
                                <div class="slider-btn">
                                    <a href="#" class="btn ss-btn" data-animation="fadeInRight" data-delay=".8s">Send
                                        Message</a>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>

        </div>
    </section>

</main>
<!-- main-area-end -->

<!-- footer -->
<app-web-footer></app-web-footer>
<!-- footer-end -->